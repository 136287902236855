import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { InvoiceDetailsPanel } from '../../../ontime/components/common/invoiceElements'
import { DoneIcon } from '../../../ontime/components/common/icons'
import {
  Details,
  HeaderExpander,
  Summary
} from '../../../ontime/components/InvoicePaid/InvoicePaid.style'
import { MappedSection } from '../../utils/upLabelMapper'
import { Divider } from '../../../ontime/components/InvoiceDetailsTypes/InvoiceDetails.style'
import InvoiceDetailRows from '../InvoiceDetailsRow/InvoiceDetailRows.component'

interface SuccessBoxProps {
  section: MappedSection
  isDivider: boolean
  highlightedRowsNumber?: number
}

const SuccessBox = ({ section, isDivider, highlightedRowsNumber = 1 }: SuccessBoxProps) => {
  const { t } = useTranslation()
  const rows = section.rows
  const highlightedRows = rows.slice(rows.length - highlightedRowsNumber)

  const detailRows = rows.slice(0, -highlightedRowsNumber)

  return (
    <InvoiceDetailsPanel style={{ paddingBottom: 0 }}>
      <HeaderExpander>
        <DoneIcon alt="" />
        <div>
          <h3>{t(section.title)}</h3>
        </div>
      </HeaderExpander>
      <Details open={true}>
        <InvoiceDetailRows rows={detailRows} />
      </Details>
      {/*
        style={{ margin: '-14px 5px 17px' }} Hard coding styles is not a best practice. We are using this because of
        using another styled component which is belongs to ontime to save time. this is the only way to override part of its styles
       */}
      {isDivider && <Divider style={{ margin: '-14px 5px 17px' }} />}
      <Summary>
        <InvoiceDetailRows rows={highlightedRows} />
      </Summary>
    </InvoiceDetailsPanel>
  )
}
export default SuccessBox
