import * as React from 'react'
import { useSelector } from 'react-redux'
import SveaFooter from '../../ontime/components/Footer/Footer.component'
import { RootState } from '../../common/store'
import {
  LoggedOutLayout,
  LoggedOutMain
} from '../../ontime/components/LoggedOutElements/loggedOutElements.style'
import { LoggedOutLogo } from '../../ontime/components/LoggedOutElements/loggedOutElements.component'
import { ThemeProvider } from 'styled-components/macro'
import LoginButton from '../../ontime/components/common/LoginButton'
import { getThemeFromState } from '../../common/themes/theme'

interface AuthThirdPartyProps {
  onStartAuth?: () => void
}

const AuthLogin = ({ onStartAuth }: AuthThirdPartyProps) => {
  const theme = getThemeFromState()
  const authPending = useSelector((state: RootState) => state.auth.authPending)

  return (
    <ThemeProvider theme={theme.auth}>
      <LoggedOutLayout>
        <LoggedOutMain>
          <LoggedOutLogo {...theme.logo} />
          <LoginButton handleOnStartAuth={onStartAuth} authPending={authPending} />
        </LoggedOutMain>
        <SveaFooter />
      </LoggedOutLayout>
    </ThemeProvider>
  )
}

export default AuthLogin
