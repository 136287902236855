import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { PrimaryButton } from '../../../components/buttons'
import { Popup, PopupBackground, PopupMessage } from '../common/popupElements'
import { BigSuccessIcon } from '../common/icons'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { setInvoicePaymentStatus } from '../../../common/state/invoice'
import { showFeedbackForm } from '../../../common/state/common'

const PaymentSuccessPopup = ({ invoiceId }: { invoiceId: string }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const [isClosed, setClosed] = useState(false)
  const [isRemoved, setRemoved] = useState(false)
  const dispatch = useDispatch()

  if (isRemoved) {
    return null
  }

  const close = () => {
    setClosed(true)
    setTimeout(() => setRemoved(true), 500)
    cleanPaymentUrl()
    setTimeout(() => dispatch(showFeedbackForm(true)), 500)
  }

  const cleanPaymentUrl = () => {
    dispatch(setInvoicePaymentStatus('paid'))
    history.replace(`/${invoiceId}`)
    return
  }

  return (
    <PopupBackground closed={isClosed}>
      <Popup closed={isClosed} align="center">
        <BigSuccessIcon alt={t('paymentSucceeded.label')!} />
        <PopupMessage>{t('paymentSucceeded.label')}</PopupMessage>
        <PrimaryButton onClick={close}>{t('close.label')}</PrimaryButton>
      </Popup>
    </PopupBackground>
  )
}

export default PaymentSuccessPopup
