import * as React from 'react'
import { Suspense } from 'react'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  RouteComponentProps
} from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { ThemeProvider } from 'styled-components/macro'
import { GlobalFont } from './common/styles'
import OntimeNotFoundPage from './ontime/routes/NotFound.page'
import OntimeMaintenancePage from './ontime/routes/Maintenance.page'
import OntimeLogoutPage from './ontime/routes/Logout.page'
import { getThemeFromState } from './common/themes/theme'
import UnallocatedPayment from './unallocated_payment/routes/UnallocatedPayment.route'
import UnallocatedPaymentAuth from './unallocated_payment/routes/Authentication.route'
import UnallocatedPaymentCallBack from './unallocated_payment/routes/Callback.route'
import { UnallocatedPaymentType } from './common/const'
import { setType } from './common/state/unallocatedPayment'

interface RouteParams {
  invoiceId: string
  paymentType: string
}

const UnallocatedPaymentsApp = () => {
  const theme = getThemeFromState()
  const dispatch = useDispatch()

  const validateRoute = (
    Component: React.ComponentType<any>,
    props: RouteComponentProps<RouteParams>
  ) => {
    const { paymentType } = props.match.params

    const isValidPaymentType = [
      UnallocatedPaymentType.OPEN_INVOICE,
      UnallocatedPaymentType.REFUND
    ].includes(paymentType as UnallocatedPaymentType)

    if (!isValidPaymentType) {
      return <Redirect to="/not-found" />
    }

    dispatch(setType(paymentType as UnallocatedPaymentType))
    return <Component {...props} />
  }

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <GlobalFont />
        <div className="App">
          <Suspense fallback="">
            <Switch>
              <Route path="/logout" exact component={OntimeLogoutPage} />
              <Route path="/callback" exact component={UnallocatedPaymentCallBack} />
              <Route path="/maintenance" exact component={OntimeMaintenancePage} />
              <Route path="/:invoiceId/:paymentType" exact component={UnallocatedPayment} />
              <Route
                path="/:invoiceId/:paymentType/auth"
                exact
                render={(props: RouteComponentProps<RouteParams>) =>
                  validateRoute(UnallocatedPaymentAuth, props)
                }
              />
              <Route path="/not-found" exact component={OntimeNotFoundPage} />
              <Route path="*" component={OntimeNotFoundPage} />
            </Switch>
          </Suspense>
        </div>
      </Router>
    </ThemeProvider>
  )
}
export default UnallocatedPaymentsApp
