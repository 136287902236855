import * as React from 'react'
import { useEffect } from 'react'
import { useParams, Redirect, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../common/store'
import { setType } from '../../common/state/unallocatedPayment'
import backend from '../../common/backend'
import { setInvoiceData, setInvoiceState } from '../../common/state/unallocatedPayment'
import { InvoiceStatus, UnallocatedPaymentType } from '../../common/const'
import { useTranslation } from 'react-i18next'
import { setMaintenance } from '../../common/state/common'
import InvoiceLoadingSpinner from '../../components/InvoiceLoadingSpinner/InvoiceLoadingSpinner.component'
import UnallocatedPaymentOpenInvoice from '../views/UnallocatedPaymentOpenInvoice.view'
import UnallocatedPaymentRefund from '../views/UnallocatedPaymentRefund.view'
import UnallocatedPaymentOpenInvoiceHandled from '../views/UnallocatedPaymentOpenInvoiceHandled.view'
import UnallocatedPaymentRefundHandled from '../views/UnallocatedPaymentRefund.Handled.view'
import NoInvoicePage from '../../ontime/routes/NoInvoice.page'
import LinkExpiredView from '../../ontime/views/LinkExpired.view'
import { createSelector } from 'reselect'
import { clearAuth, setAuthToken } from '../../common/state/auth'
import { AxiosResponse } from 'axios'
import { UnallocatedPaymentInvoice } from '../../common/types/UnallocatedPayment'
import { clearInvoiceData } from '../../common/state/invoice'

interface RouteParams {
  invoiceId: string
  paymentType: UnallocatedPaymentType
}

const selectUnallocatedPayment = (state: RootState) => state.unallocatedPayment
const selectAuth = (state: RootState) => state.auth
const selectCommon = (state: RootState) => state.common

const makeSelectProps = createSelector(
  [selectUnallocatedPayment, selectAuth, selectCommon],
  (unallocatedPayment, auth, common) => ({
    isAuthRequired: common.authRequired,
    invoiceId: common.invoiceId,
    jwtToken: auth.jwtToken,
    sessionId: auth.sessionId,
    invoiceState: unallocatedPayment.invoiceState,
    type: unallocatedPayment.type
  })
)

const UnallocatedPayment: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { paymentType } = useParams<RouteParams>()
  const { t } = useTranslation()

  const { isAuthRequired, invoiceId, jwtToken, sessionId, invoiceState, type } =
    useSelector(makeSelectProps)

  useEffect(() => {
    if (paymentType !== type) {
      const isValidPaymentType = [
        UnallocatedPaymentType.OPEN_INVOICE,
        UnallocatedPaymentType.REFUND
      ].includes(paymentType as UnallocatedPaymentType)

      if (!isValidPaymentType) {
        history.push('/not-found')
      } else {
        dispatch(setType(paymentType as UnallocatedPaymentType))
      }
    }
  }, [paymentType, type])

  useEffect(() => {
    const getUnallocatedPaymentData = async () => {
      if (!jwtToken || !sessionId) return

      dispatch(setInvoiceState(InvoiceStatus.LOADING))
      dispatch(clearInvoiceData())

      try {
        const response = await backend.getUnallocatedPaymentInvoice(invoiceId, jwtToken, sessionId)

        switch (response.data.status) {
          case InvoiceStatus.SENT:
          case InvoiceStatus.NEW:
          case InvoiceStatus.SENDING:
            dispatch(setInvoiceData({ status: InvoiceStatus.OK, data: response.data }))
            dispatch(setInvoiceState(InvoiceStatus.OK))

            break
          case InvoiceStatus.HANDLED:
            {
              const modifiedAxiosResponse = addAmountsToInvoices(response, type)
              dispatch(
                setInvoiceData({
                  status: InvoiceStatus.OK,
                  data: modifiedAxiosResponse.data
                })
              )

              dispatch(setInvoiceState(InvoiceStatus.HANDLED))
            }
            break
          case InvoiceStatus.EXPIRED:
            dispatch(setInvoiceState(InvoiceStatus.EXPIRED))
            break
          default:
            console.error('Invalid invoiceState on response:', response.data.status)
        }
      } catch (err: any) {
        handleError(err)
      }
    }

    const addAmountsToInvoices = (
      response: AxiosResponse<UnallocatedPaymentInvoice>,
      type: UnallocatedPaymentType | null
    ) => {
      if (type !== UnallocatedPaymentType.OPEN_INVOICE) {
        // Merging firstName and lastName together
        const modifiedResponse = { ...response.data }
        if (modifiedResponse.refund) {
          const name = `${modifiedResponse.refund.firstName} ${modifiedResponse.refund.lastName}`
          delete modifiedResponse.refund.firstName
          delete modifiedResponse.refund.lastName

          modifiedResponse.refund.name = name
        }
        return {
          ...response,
          data: modifiedResponse
        }
      }

      // for the first version response data is manipulated
      const modifiedResponse = { ...response.data }
      if (modifiedResponse.openInvoices.length > 0) {
        modifiedResponse.openInvoices[0] = {
          ...modifiedResponse.openInvoices[0],
          originalAmount: modifiedResponse.unallocatedAmount,
          allocatedAmount: modifiedResponse.unallocatedAmount,
          amountToBePaid: 0
        }
      }

      return {
        ...response,
        data: modifiedResponse
      }
    }

    const handleError = (err: any) => {
      console.error('Error fetching unallocated payment data:', err)

      if (err.response) {
        const status = err.response.status

        switch (status) {
          case 400:
            if (err.response.data.error === 'signicat_error') {
              dispatch(setInvoiceState(InvoiceStatus.AUTH_FAILED))
            }
            break
          case 401:
            if (err.response.data.subError === 'unauthorized') {
              dispatch(setInvoiceState(InvoiceStatus.AUTH_NOT_ALLOWED))
            } else if (err.response.data.subError === 'failed') {
              dispatch(setInvoiceState(InvoiceStatus.AUTH_FAILED))
            } else {
              // Signifies expired jwt token
              dispatch(clearAuth())
              dispatch(setAuthToken(null))
              dispatch(setInvoiceState(InvoiceStatus.AUTH_REQUIRED))
            }
            break
          case 500:
            dispatch(setInvoiceState(InvoiceStatus.ERROR))
            break
          case 503:
            dispatch(setMaintenance())
            break
          default:
            dispatch(setInvoiceState(InvoiceStatus.ERROR))
        }
      } else {
        dispatch(setInvoiceState(InvoiceStatus.ERROR))
      }
    }

    getUnallocatedPaymentData()
  }, [invoiceId])

  if (isAuthRequired && !(jwtToken && sessionId)) {
    return <Redirect to={`/${invoiceId}/${paymentType}/auth`} />
  }

  const renderHandledComponent = (type: UnallocatedPaymentType | null) => {
    switch (type) {
      case 'open-invoice':
        return <UnallocatedPaymentOpenInvoiceHandled />
      case 'refund':
        return <UnallocatedPaymentRefundHandled />
      default:
        return <NoInvoicePage />
    }
  }

  const renderDefaultComponent = (type: UnallocatedPaymentType | null) => {
    switch (type) {
      case 'open-invoice':
        return <UnallocatedPaymentOpenInvoice />
      case 'refund':
        return <UnallocatedPaymentRefund />
      default:
        return <NoInvoicePage />
    }
  }

  const renderContent = () => {
    if (invoiceState === InvoiceStatus.LOADING || invoiceState === InvoiceStatus.NOT_LOADED) {
      return <InvoiceLoadingSpinner text={t('loading.label')} />
    }

    if (invoiceState === InvoiceStatus.EXPIRED) {
      return <LinkExpiredView />
    }

    if (invoiceState === InvoiceStatus.ERROR) {
      return <NoInvoicePage />
    }

    if (invoiceState === InvoiceStatus.HANDLED) {
      return renderHandledComponent(type)
    }

    return renderDefaultComponent(type)
  }

  return <>{renderContent()}</>
}

export default UnallocatedPayment
