import { InvoiceStatus, InvoiceSubpage } from '../const'
import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'
import InvoiceData from '../types/InvoiceData'

interface InvoiceState {
  invoiceState: InvoiceStatus
  data: unknown
  invoiceId: string | null
  invoiceData: InvoiceData | null
  // store storing the actual amount to be paid in the local storage over Svea Payments redirect
  // store also invoice ID to make sure it will be displayed only for a correct invoice
  storedInvoiceId: string | null
  storedAmountToBePaid: number | null
  previousInvoiceId: string | null
  storedSubpage: InvoiceSubpage | null
}

const INITIAL_STATE: InvoiceState = {
  invoiceState: InvoiceStatus.NOT_LOADED,
  data: null,
  invoiceId: null,
  invoiceData: null,
  storedInvoiceId: null,
  storedAmountToBePaid: null,
  previousInvoiceId: null,
  storedSubpage: null
}

const invoiceSlice = createSlice({
  name: 'invoice',
  initialState: INITIAL_STATE,
  reducers: {
    setInvoiceState: (state: Draft<InvoiceState>, action: PayloadAction<InvoiceStatus>) => {
      state.invoiceState = action.payload
    },

    updateInvoiceData: (
      state: Draft<InvoiceState>,
      action: PayloadAction<{ status: InvoiceStatus; data: InvoiceData }>
    ) => {
      state.invoiceState = action.payload.status
      state.invoiceData = action.payload.data
    },

    setInvoiceId: (state: Draft<InvoiceState>, action: PayloadAction<string>) => {
      state.invoiceId = action.payload
    },

    setAmountToBePaid: (state: Draft<InvoiceState>, action: PayloadAction<number | null>) => {
      state.storedInvoiceId = state.invoiceId
      state.storedAmountToBePaid = action.payload
    },

    setPreviousInvoiceId: (state: Draft<InvoiceState>, action: PayloadAction<string | null>) => {
      state.previousInvoiceId = action.payload
    },

    setInvoiceSubpage: (
      state: Draft<InvoiceState>,
      action: PayloadAction<InvoiceSubpage | null>
    ) => {
      state.storedSubpage = action.payload
    },

    setInvoicePaymentStatus: (state: Draft<InvoiceState>, action: PayloadAction<string>) => {
      if (state.invoiceData) {
        state.invoiceData.invoice.status = action.payload
      }
    },

    clearInvoiceData: (state: Draft<InvoiceState>) => {
      state.invoiceState = InvoiceStatus.NOT_LOADED
      state.invoiceId = null
      state.invoiceData = null
      state.data = null
      state.storedInvoiceId = null
      state.storedAmountToBePaid = null
      state.previousInvoiceId = null
      state.storedSubpage = null
    }
  }
})

export const {
  setInvoiceState,
  updateInvoiceData,
  setInvoiceId,
  setAmountToBePaid,
  setPreviousInvoiceId,
  setInvoiceSubpage,
  setInvoicePaymentStatus,
  clearInvoiceData
} = invoiceSlice.actions
export default invoiceSlice.reducer
