import * as React from 'react'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { InvoiceDetailsPanel } from '../../../ontime/components/common/invoiceElements'

const MassageBoxWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  padding: 50px;

  & p {
    font-style: italic;
    font-size: 0.875rem;
    line-height: 1.5rem;
    margin: 19px 0;
  }
`

interface MessageBoxProps {
  message: string
}

const MessageBox = ({ message }: MessageBoxProps) => {
  const { t } = useTranslation()
  const messageText = t(message)
  return (
    <InvoiceDetailsPanel>
      <MassageBoxWrapper
        dangerouslySetInnerHTML={{
          __html: messageText
        }}></MassageBoxWrapper>
    </InvoiceDetailsPanel>
  )
}

export default MessageBox
