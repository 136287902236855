import * as React from 'react'
import styled from 'styled-components/macro'
import { Select, MenuItem, FormControl, TextField, SvgIcon } from '@mui/material'

export const RatingSelectorWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 10px;
  margin-bottom: 20px;
`

export const RatingOption = styled.div<{ isSelected: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  .circle {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: ${(props) => (props.isSelected ? '#00699A' : '#00aabb')};
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 18px;
    margin-bottom: 5px;
    transition: background-color 0.3s;
  }

  .label {
    font-size: 14px;
  }
`

export const StyledFormControl = styled(FormControl)`
  margin-top: 20px;
  width: 100%;
`

export const StyledSelect = styled(Select)`
  .MuiOutlinedInput-root {
    border-radius: 4px;
    padding: 8px 12px;
    border: 1px solid #b3b3b3;
    font-size: 14px;
    color: #333;
    transition: border-color 0.2s;

    &:hover .MuiOutlinedInput-notchedOutline {
      border-color: #666;
    }

    &.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: #0e568b;
    }

    /* Rotate the icon when the select is open */
    &.Mui-focused .MuiSelect-icon,
    &.MuiSelect-select[aria-expanded='true'] .MuiSelect-icon {
      transform: rotate(180deg);
    }
  }

  .MuiSelect-icon {
    color: #0e568b;
    transition: transform 0.2s;
  }
`

export const StyledMenuItem = styled(MenuItem)`
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    background-color: rgba(14, 86, 139, 0.1);
  }

  &.Mui-selected {
    background-color: rgba(14, 86, 139, 0.2) !important;
    color: #0e568b;
  }

  &.Mui-selected:hover {
    background-color: rgba(14, 86, 139, 0.3) !important;
  }
`

export const StyledTextField = styled((props) => <TextField {...props} />)`
  margin-top: 20px !important;
  width: 100%;
  margin-bottom: 10px !important;

  .MuiOutlinedInput-root {
    border-color: #0e568b;
    &:hover .MuiOutlinedInput-notchedOutline {
      border-color: #0e568b;
    }

    &.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: #0e568b;
    }
  }
`
export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 20px;
  gap: 10px;
`

export const ErrorLabel = styled.div`
  margin-top: 5px;
  color: #d32f2f;
  font-size: 12px;
`
