interface Row {
  [key: string]: string
}

interface Section {
  title: string
  rows: Row[]
}

export interface UPOpenInvoiceUILabelGroup {
  UP_DETAILS?: Section
  ALLOCATE_TO_INVOICE?: Section
  ALLOCATION_SUCCESS?: Section
  ALLOCATED_TO_INVOICE?: Section
}

export interface UPRefundUILabelGroup {
  UP_DETAILS?: Section
  UP_REFUND_FORM: string
}

export const UP_OPEN_INVOICE: UPOpenInvoiceUILabelGroup[] = [
  {
    UP_DETAILS: {
      title: 'unallocatedPaymentDetails.title.label',
      rows: [
        {
          registrationDate: 'unallocatedPaymentDetails.registrationDate.label',
          type: 'date',
          fontWeight: 'regular'
        },
        {
          recipientName: 'unallocatedPaymentDetails.recipientName.label',
          type: 'string',
          fontWeight: 'regular'
        },
        {
          referenceNumber: 'unallocatedPaymentDetails.referenceNumber.label',
          type: 'string',
          fontWeight: 'regular'
        },
        {
          unallocatedAmount: 'unallocatedPaymentDetails.unallocatedAmount.label',
          type: 'amount',
          fontWeight: 'bold'
        }
      ]
    },
    ALLOCATE_TO_INVOICE: {
      title: 'unallocatedPaymentAllocateToInvoice.title.label',
      rows: [
        {
          dueDate: 'unallocatedPaymentAllocateToInvoice.dueDate.label',
          type: 'date',
          fontWeight: 'regular'
        },
        {
          invoiceAmount: 'unallocatedPaymentAllocateToInvoice.invoiceAmount.label',
          type: 'amount',
          fontWeight: 'regular'
        },
        {
          recipientName: 'unallocatedPaymentAllocateToInvoice.recipientName.label',
          type: 'string',
          fontWeight: 'regular'
        },
        {
          iban: 'unallocatedPaymentAllocateToInvoice.iban.label',
          type: 'string',
          fontWeight: 'regular'
        },
        {
          referenceNumber: 'unallocatedPaymentAllocateToInvoice.referenceNumber.label',
          type: 'string',
          fontWeight: 'regular'
        }
      ]
    }
  },

  {
    ALLOCATION_SUCCESS: {
      title: 'unallocatedPaymentAllocationSuccess.title.label',
      rows: [
        {
          merchantName: 'unallocatedPaymentAllocationSuccess.merchant.label',
          type: 'string',
          fontWeight: 'bold'
        },
        {
          unallocatedAmount: 'unallocatedPaymentAllocateToInvoice.allocatedAmount.label',
          type: 'amount',
          fontWeight: 'regular'
        }
      ]
    },
    ALLOCATED_TO_INVOICE: {
      title: 'unallocatedPaymentAllocatedToInvoice.title.label',
      rows: [
        {
          dueDate: 'unallocatedPaymentAllocatedToInvoice.dueDate.label',
          type: 'date',
          fontWeight: 'regular'
        },
        {
          unallocatedAmount: 'unallocatedPaymentAllocatedToInvoice.initialAmount.label',
          type: 'amount',
          fontWeight: 'regular'
        },
        {
          originalAmount: 'unallocatedPaymentAllocatedToInvoice.originalAmount.label',
          type: 'amount',
          fontWeight: 'regular'
        },
        {
          amountToBePaid: 'unallocatedPaymentAllocatedToInvoice.amountToBePaid.label',
          type: 'amount',
          fontWeight: 'regular'
        },
        {
          recipientName: 'unallocatedPaymentAllocatedToInvoice.recipientName.label',
          type: 'string',
          fontWeight: 'regular'
        },
        {
          iban: 'unallocatedPaymentAllocatedToInvoice.accountNumber.label',
          type: 'string',
          fontWeight: 'regular'
        },
        {
          referenceNumber: 'unallocatedPaymentAllocatedToInvoice.referenceNumber.label',
          type: 'string',
          fontWeight: 'regular'
        }
      ]
    }
  }
]

export interface RefundFromRows {
  title: string
  rows: {
    key: string
    label: string
    type: 'string' | 'iban'
    fontWeight: 'regular' | 'bold'
    isDisabled: boolean
    isRequired: boolean
  }[]
}

export interface UPRefundUIItemGroup {
  UP_REFUND_DETAILS?: Section
  UP_REFUND_FROM_ROWS?: RefundFromRows
  REFUND_SUCCESS?: Section
  REFUND_SUCCESS_MESSAGE?: { messageAFS: string; messageWP: string }
}

export const UP_REFUND: UPRefundUIItemGroup[] = [
  {
    UP_REFUND_DETAILS: {
      title: 'unallocatedPaymentRefundDetails.title.label',
      rows: [
        {
          registrationDate: 'unallocatedPaymentRefundDetails.registrationDate.label',
          type: 'date',
          fontWeight: 'regular'
        },
        {
          recipientName: 'unallocatedPaymentRefundDetails.recipientName.label',
          type: 'string',
          fontWeight: 'regular'
        },
        {
          referenceNumber: 'unallocatedPaymentRefundDetails.referenceNumber.label',
          type: 'string',
          fontWeight: 'regular'
        },
        {
          unallocatedAmount: 'unallocatedPaymentRefundDetails.paidAmount.label',
          type: 'amount',
          fontWeight: 'regular'
        },
        {
          handlingFee: 'unallocatedPaymentRefundDetails.handlingFee.label',
          type: 'amount',
          fontWeight: 'regular'
        },
        {
          refundableAmount: 'unallocatedPaymentRefundDetails.refundableAmount.label',
          type: 'amount',
          fontWeight: 'bold'
        }
      ]
    },
    UP_REFUND_FROM_ROWS: {
      title: 'unallocatedPaymentRefundForm.title.label',
      rows: [
        {
          key: 'firstName',
          label: 'unallocatedPaymentRefundFromDetails.firstName.label',
          type: 'string',
          fontWeight: 'regular',
          isDisabled: false,
          isRequired: true
        },
        {
          key: 'lastName',
          label: 'unallocatedPaymentRefundFromDetails.lastName.label',
          type: 'string',
          fontWeight: 'regular',
          isDisabled: false,
          isRequired: true
        },
        {
          key: 'iban',
          label: 'unallocatedPaymentRefundFromDetails.iban.label',
          type: 'iban',
          fontWeight: 'regular',
          isDisabled: false,
          isRequired: true
        },
        {
          key: 'bic',
          label: 'unallocatedPaymentRefundFromDetails.bic.label',
          type: 'string',
          fontWeight: 'regular',
          isDisabled: true,
          isRequired: false
        }
      ]
    }
  },
  {
    REFUND_SUCCESS: {
      title: 'unallocatedPaymentRefundSuccess.title.label',
      rows: [
        {
          name: 'unallocatedPaymentRefundSuccess.name.label',
          type: 'string',
          fontWeight: 'regular'
        },
        {
          bic: 'unallocatedPaymentRefundSuccess.bic.label',
          type: 'string',
          fontWeight: 'regular'
        },
        {
          iban: 'unallocatedPaymentRefundSuccess.iban.label',
          type: 'string',
          fontWeight: 'regular'
        },
        {
          amount: 'unallocatedPaymentRefundSuccess.amount.label',
          type: 'amount',
          fontWeight: 'regular'
        },
        {
          handlingFee: 'unallocatedPaymentRefundSuccess.handlingFee.label',
          type: 'amount',
          fontWeight: 'regular'
        },
        {
          refundableAmount: 'unallocatedPaymentRefundSuccess.refundableAmount.label',
          type: 'amount',
          fontWeight: 'regular'
        }
      ]
    },
    REFUND_SUCCESS_MESSAGE: {
      messageAFS: 'unallocatedPaymentRefundSuccess.messageAFS.label',
      messageWP: 'unallocatedPaymentRefundSuccess.messageWP.label'
    }
  }
]
