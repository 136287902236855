import { formatIban } from '../../common/helpers'
import { UnallocatedPaymentInvoice } from '../../common/types/UnallocatedPayment'

interface Row {
  [key: string]: string
}

interface Section {
  title: string
  rows: Row[]
}

export interface MappedRow {
  [key: string]: any
}

export interface MappedSection {
  title: string
  rows: MappedRow[]
}

const findValueByKey = (obj: UnallocatedPaymentInvoice, key: string): string | undefined => {
  const search = (obj: any, key: string): string | undefined => {
    if (obj === null || typeof obj !== 'object') {
      return undefined
    }

    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = obj[key]
      return typeof value === 'string' ? value : value?.toString()
    }

    for (const k in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, k)) {
        const value = obj[k]
        if (typeof value === 'object') {
          const result = search(value, key)
          if (result !== undefined) {
            return result
          }
        } else if (Array.isArray(value)) {
          for (const item of value) {
            const result = search(item, key)
            if (result !== undefined) {
              return result
            }
          }
        }
      }
    }

    return undefined
  }

  return search(obj, key)
}

export const mapValuesToLabelsForSection = (
  section: Section | undefined,
  invoiceData: UnallocatedPaymentInvoice | null
): MappedSection | undefined => {
  if (!section || !invoiceData) {
    return undefined
  }

  const newRows = section.rows.map((row) => {
    const key = Object.keys(row).find((k) => k !== 'type' && k !== 'fontWeight')
    if (key) {
      let value = findValueByKey(invoiceData, key)
      if (key === 'iban' && value !== undefined) {
        value = formatIban(value)
      }
      return { ...row, value }
    }
    return row
  })

  return { ...section, rows: newRows as MappedRow[] }
}
