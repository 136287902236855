import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'
import { Branding, UI } from '../const'

interface CommonState {
  language: string
  theme: Branding
  maintenance: boolean
  ui: string
  authRequired: boolean
  invoiceId: string
  showFeedbackForm: boolean
  showFeedbackSuccess: boolean
}

const INITIAL_STATE: CommonState = {
  language: '',
  theme: Branding.NOT_FOUND,
  maintenance: false,
  ui: '',
  authRequired: false,
  invoiceId: '',
  showFeedbackForm: false,
  showFeedbackSuccess: false
}

const commonSlice = createSlice({
  name: 'common',
  initialState: INITIAL_STATE,
  reducers: {
    setLanguage: (state: Draft<CommonState>, action: PayloadAction<string>) => {
      state.language = action.payload
    },

    setThemeName: (state: Draft<CommonState>, action: PayloadAction<Branding>) => {
      state.theme = action.payload
    },

    setMaintenance: (state: Draft<CommonState>) => {
      state.maintenance = true
    },

    clearMaintenance: (state: Draft<CommonState>) => {
      state.maintenance = false
    },
    setUIName: (state: Draft<CommonState>, action: PayloadAction<UI>) => {
      state.ui = action.payload
    },
    setAuthRequired: (state: Draft<CommonState>, action: PayloadAction<boolean>) => {
      state.authRequired = action.payload
    },
    setInvoiceId: (state: Draft<CommonState>, action: PayloadAction<string>) => {
      state.invoiceId = action.payload
    },
    showFeedbackForm: (state: Draft<CommonState>, action: PayloadAction<boolean>) => {
      state.showFeedbackForm = action.payload
    },
    showFeedbackSuccess: (state: Draft<CommonState>, action: PayloadAction<boolean>) => {
      state.showFeedbackSuccess = action.payload
    }
  }
})

export const {
  setLanguage,
  setThemeName,
  setMaintenance,
  clearMaintenance,
  setUIName,
  setAuthRequired,
  setInvoiceId,
  showFeedbackForm,
  showFeedbackSuccess
} = commonSlice.actions
export default commonSlice.reducer
