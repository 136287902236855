import { DefaultTheme } from 'styled-components/macro'
import { PrimaryButtonAlternate, SecondaryButton } from '../../components/buttons'
import { InvoicerAuthMethod } from '../const'

type ThemeName = 'ontime' | 'serbia' | 'creditExpress' | 'inkasso' | 'unallocatedPayment'

const themes: { [name in ThemeName]: DefaultTheme } = {
  // OnTime UI default theme
  ontime: {
    text: '#434343',
    heading: '#00325C',
    link: '#00699A',
    button: '#00699A',
    muted: '#9B9B9B',
    divider: '#D0E3E9',
    background: '#F8F8F4',
    panel: '#FFFFFF',
    inputBackground: '#FFFFFF',
    inputForeground: '#434343',
    pdfBackground: '#EDEDE9',
    fontFamily: 'Asap',
    favicon: '/img/svea-favicon.ico',
    selectIcon: '/img/chevron.svg',
    selectBoxShadow: '0 4px 4px rgba(0, 0, 0, 0.25)',
    headerBackground: '#FFFFFF',
    logoutLinkColor: '#00699A',
    buttonComponent: PrimaryButtonAlternate,
    marketingButtonColor: '#01699A',
    marketingButtonBackgroundColor: '#FFFFFF',
    marketingButtonBorderColor: '#01699A',
    UnallocatedPaymentButtonColor: '#FFFFFF',
    UnallocatedPaymentButtonBackgroundColor: '#00699A',
    UnallocatedPaymentButtonBorderColor: '#00699A',
    authMethod: InvoicerAuthMethod.IDS4_ONTIME,
    buttonIconPrimaryColor: '#01699A',
    buttonIconSecondaryColor: '#FFFFFF',
    buttonIconDisabledColor: '#00000042',
    languages: ['fi', 'sv', 'en'],
    footer: 'default',
    invoiceTitle: {
      background: 'url("/img/bg_gradient_circles.png") center center/cover',
      h1Color: '#00325C',
      h1FontStyle: 'italic',
      h1FontSize: '32px'
    },
    logo: {
      image: '/img/svea_logo_72px.png',
      alt: 'Svea',
      height: '72'
    },
    smallLogo: {
      image: '/img/svea_logo_small.png',
      alt: 'Svea',
      height: '32'
    },
    logout: {
      backgroundImage: 'url(/img/ontime_landing_bg.jpg)',
      h2FontStyle: 'italic',
      h2FontSize: '32px',
      h2FontWeight: 'inherit',
      h2TextTranform: 'inherit'
    },
    auth: {
      text: 'white',
      heading: '#434343',
      link: 'white',
      button: '#00699A',
      muted: 'white',
      divider: 'white',
      background: '#00699A',
      panel: '#00325C',
      inputBackground: '#FFFFFF',
      inputForeground: '#434343',
      pdfBackground: '#00699A',
      fontFamily: 'Asap',
      favicon: '/img/svea-favicon.ico',
      selectIcon: '/img/chevron.svg',
      selectBoxShadow: '0 4px 4px rgba(0, 0, 0, 0.25)',
      headerBackground: '#FFFFFF',
      logoutLinkColor: '#00699A',
      buttonComponent: PrimaryButtonAlternate,
      authMethod: InvoicerAuthMethod.IDS4_ONTIME,
      languages: ['fi', 'sv', 'en'],
      footer: 'default',
      invoiceTitle: {
        background: 'url("/img/bg_gradient_circles.png") center center/cover',
        h1Color: '#00325C',
        h1FontStyle: 'italic',
        h1FontSize: '32px'
      },
      logo: {
        image: '/img/svea_logo_72px.png',
        alt: 'Svea',
        height: '72'
      },
      smallLogo: {
        image: '/img/svea_logo_small.png',
        alt: 'Svea',
        height: '32'
      },
      logout: {
        backgroundImage: 'url(/img/ontime_landing_bg.jpg)',
        h2FontStyle: 'italic',
        h2FontSize: '32px',
        h2FontWeight: 'inherit',
        h2TextTranform: 'inherit'
      }
    }
  },
  // OnTime UI Serbia theme
  serbia: {
    text: '#434343',
    heading: '#00325C',
    link: '#00699A',
    button: '#00699A',
    muted: '#9B9B9B',
    divider: '#D0E3E9',
    background: '#F8F8F4',
    panel: '#FFFFFF',
    inputBackground: '#FFFFFF',
    inputForeground: '#434343',
    pdfBackground: '#EDEDE9',
    fontFamily: 'Asap',
    favicon: '/img/svea-favicon.ico',
    selectIcon: '/img/chevron.svg',
    selectBoxShadow: '0 4px 4px rgba(0, 0, 0, 0.25)',
    headerBackground: '#FFFFFF',
    logoutLinkColor: '#00699A',
    buttonComponent: PrimaryButtonAlternate,
    marketingButtonColor: '#00325C',
    marketingButtonBackgroundColor: '#01699A',
    marketingButtonBorderColor: '#01699A',
    UnallocatedPaymentButtonColor: '#FFFFFF',
    UnallocatedPaymentButtonBackgroundColor: '#00699A',
    UnallocatedPaymentButtonBorderColor: '#00699A',
    authMethod: InvoicerAuthMethod.PERSONAL_DATA_BIRTHDAY,
    buttonIconPrimaryColor: '#01699A',
    buttonIconSecondaryColor: '#FFFFFF',
    buttonIconDisabledColor: '#00000042',
    languages: ['sr', 'en'],
    footer: 'serbia',
    invoiceTitle: {
      background: 'url("/img/bg_gradient_circles.png") center center/cover',
      h1Color: '#00325C',
      h1FontStyle: 'italic',
      h1FontSize: '32px'
    },
    logo: {
      image: '/img/svea_logo_72px.png',
      alt: 'Svea',
      height: '72'
    },
    smallLogo: {
      image: '/img/svea_logo_small.png',
      alt: 'Svea',
      height: '32'
    },
    logout: {
      backgroundImage: 'url(/img/ontime_landing_bg.jpg)',
      h2FontStyle: 'italic',
      h2FontSize: '32px',
      h2FontWeight: 'inherit',
      h2TextTranform: 'inherit'
    },
    auth: {
      text: 'white',
      heading: '#434343',
      link: 'white',
      button: '#00699A',
      muted: 'white',
      divider: 'white',
      background: '#00699A',
      panel: '#00325C',
      inputBackground: '#FFFFFF',
      inputForeground: '#434343',
      pdfBackground: '#00699A',
      fontFamily: 'Asap',
      favicon: '/img/svea-favicon.ico',
      selectIcon: '/img/chevron.svg',
      selectBoxShadow: '0 4px 4px rgba(0, 0, 0, 0.25)',
      headerBackground: '#FFFFFF',
      logoutLinkColor: '#00699A',
      buttonComponent: PrimaryButtonAlternate,
      authMethod: InvoicerAuthMethod.PERSONAL_DATA_BIRTHDAY,
      languages: ['sr', 'en'],
      footer: 'serbia',
      invoiceTitle: {
        background: 'url("/img/bg_gradient_circles.png") center center/cover',
        h1Color: '#00325C',
        h1FontStyle: 'italic',
        h1FontSize: '32px'
      },
      logo: {
        image: '/img/svea_logo_72px.png',
        alt: 'Svea',
        height: '72'
      },
      smallLogo: {
        image: '/img/svea_logo_small.png',
        alt: 'Svea',
        height: '32'
      },
      logout: {
        backgroundImage: 'url(/img/ontime_landing_bg.jpg)',
        h2FontStyle: 'italic',
        h2FontSize: '32px',
        h2FontWeight: 'inherit',
        h2TextTranform: 'inherit'
      }
    }
  },
  // OnTime UI CreditExpress theme
  creditExpress: {
    text: '#434343',
    heading: '#00325C',
    link: '#1D1D1B',
    button: '#EE5340',
    muted: '#9B9B9B',
    divider: '#D0E3E9',
    background: '#F8F8F4',
    panel: '#FFFFFF',
    inputBackground: '#FFFFFF',
    inputForeground: '#434343',
    pdfBackground: '#EDEDE9',
    fontFamily: 'Poppins',
    favicon: '/img/creditexpress-favicon.png',
    selectIcon: '/img/chevron-white.svg',
    selectBoxShadow: 'none',
    headerBackground: '#EE5340',
    logoutLinkColor: '#FFFFFF',
    buttonComponent: SecondaryButton,
    marketingButtonColor: '#00325C',
    marketingButtonBackgroundColor: '#01699A',
    marketingButtonBorderColor: '#01699A',
    UnallocatedPaymentButtonColor: '#FFFFFF',
    UnallocatedPaymentButtonBackgroundColor: '#00699A',
    UnallocatedPaymentButtonBorderColor: '#00699A',
    authMethod: InvoicerAuthMethod.PERSONAL_DATA_BIRTHDAY,
    buttonIconPrimaryColor: '#EE5340',
    buttonIconSecondaryColor: '#FFFFFF',
    buttonIconDisabledColor: '#00000042',
    languages: ['sr', 'en'],
    footer: 'creditexpress',
    invoiceTitle: {
      background: '#FAE053',
      h1Color: '#434343',
      h1FontStyle: 'normal',
      h1FontSize: '24px'
    },
    logo: {
      image: '/img/creditexpress.svg',
      alt: 'CreditExpress',
      height: '50'
    },
    smallLogo: {
      image: '/img/creditexpress.svg',
      alt: 'CreditExpress',
      height: '32'
    },
    logout: {
      backgroundImage: 'none',
      h2FontStyle: 'normal',
      h2FontSize: '24px',
      h2FontWeight: '500',
      h2TextTranform: 'uppercase'
    },
    auth: {
      text: 'white',
      heading: 'white',
      link: 'white',
      button: 'white',
      muted: 'white',
      divider: 'white',
      background: '#EE5340',
      panel: '#EE5340',
      inputBackground: '#EE5340',
      inputForeground: 'white',
      pdfBackground: '#EE5340',
      fontFamily: 'Poppins',
      favicon: '/img/creditexpress-favicon.png',
      selectIcon: '/img/chevron-white.svg',
      selectBoxShadow: 'none',
      headerBackground: '#EE5340',
      logoutLinkColor: '#FFFFFF',
      buttonComponent: SecondaryButton,
      authMethod: InvoicerAuthMethod.PERSONAL_DATA_BIRTHDAY,
      languages: ['sr', 'en'],
      footer: 'creditexpress',
      invoiceTitle: {
        background: '#FAE053',
        h1Color: '#434343',
        h1FontStyle: 'normal',
        h1FontSize: '24px'
      },
      logo: {
        image: '/img/creditexpress.svg',
        alt: 'CreditExpress',
        height: '50'
      },
      smallLogo: {
        image: '/img/creditexpress.svg',
        alt: 'CreditExpress',
        height: '32'
      },
      logout: {
        backgroundImage: 'none',
        h2FontStyle: 'normal',
        h2FontSize: '24px',
        h2FontWeight: '500',
        h2TextTranform: 'uppercase'
      }
    }
  },
  // Inkasso UI default theme
  inkasso: {
    text: '#434343',
    heading: '#00325C',
    link: '#00699A',
    button: '#00829A',
    muted: '#9B9B9B',
    divider: '#ECECEC',
    background: '#F8F8F4',
    panel: '#FFFFFF',
    inputBackground: '#FFFFFF',
    inputForeground: '#434343',
    pdfBackground: '#EDEDE9',
    fontFamily: 'Poppins',
    favicon: '/img/svea-favicon.ico',
    selectIcon: '/img/chevron.svg',
    selectBoxShadow: '0 4px 4px rgba(0, 0, 0, 0.25)',
    headerBackground: '#FFFFFF',
    logoutLinkColor: '#00699A',
    buttonComponent: PrimaryButtonAlternate,
    marketingButtonColor: '#00325C',
    marketingButtonBackgroundColor: '#01699A',
    marketingButtonBorderColor: '#01699A',
    UnallocatedPaymentButtonColor: '#FFFFFF',
    UnallocatedPaymentButtonBackgroundColor: '#00699A',
    UnallocatedPaymentButtonBorderColor: '#00699A',
    authMethod: InvoicerAuthMethod.IDS4_INKASSO,
    buttonIconPrimaryColor: '#01699A',
    buttonIconSecondaryColor: '#FFFFFF',
    buttonIconDisabledColor: '#00000042',
    languages: ['sv', 'en'],
    footer: 'default',
    invoiceTitle: {
      background: 'url("/img/bg_gradient_circles.png") center center/cover',
      h1Color: '#00325C',
      h1FontStyle: 'italic',
      h1FontSize: '32px'
    },
    logo: {
      image: '/img/svea_logo_72px.png',
      alt: 'Svea',
      height: '72'
    },
    smallLogo: {
      image: '/img/svea_logo_small.png',
      alt: 'Svea',
      height: '32'
    },
    logout: {
      backgroundImage: 'url(/img/ontime_landing_bg.jpg)',
      h2FontStyle: 'italic',
      h2FontSize: '32px',
      h2FontWeight: 'inherit',
      h2TextTranform: 'inherit'
    },
    auth: {
      text: 'white',
      heading: 'white',
      link: 'white',
      button: '#00829A',
      muted: 'white',
      divider: 'white',
      background: '#00829A',
      panel: '#00325C',
      inputBackground: '#FFFFFF',
      inputForeground: '#434343',
      pdfBackground: '#00829A',
      fontFamily: 'Asap',
      favicon: '/img/svea-favicon.ico',
      selectIcon: '/img/chevron.svg',
      selectBoxShadow: '0 4px 4px rgba(0, 0, 0, 0.25)',
      headerBackground: '#FFFFFF',
      logoutLinkColor: '#00699A',
      buttonComponent: PrimaryButtonAlternate,
      authMethod: InvoicerAuthMethod.IDS4_INKASSO,
      languages: ['sv', 'en'],
      footer: 'default',
      invoiceTitle: {
        background: 'url("/img/bg_gradient_circles.png") center center/cover',
        h1Color: '#00325C',
        h1FontStyle: 'italic',
        h1FontSize: '32px'
      },
      logo: {
        image: '/img/svea_logo_72px.png',
        alt: 'Svea',
        height: '72'
      },
      smallLogo: {
        image: '/img/svea_logo_small.png',
        alt: 'Svea',
        height: '32'
      },
      logout: {
        backgroundImage: 'url(/img/ontime_landing_bg.jpg)',
        h2FontStyle: 'italic',
        h2FontSize: '32px',
        h2FontWeight: 'inherit',
        h2TextTranform: 'inherit'
      }
    }
  },
  unallocatedPayment: {
    text: '#434343',
    heading: '#00325C',
    link: '#00699A',
    button: '#00699A',
    muted: '#9B9B9B',
    divider: '#D0E3E9',
    background: '#F8F8F4',
    panel: '#FFFFFF',
    inputBackground: '#FFFFFF',
    inputForeground: '#434343',
    pdfBackground: '#EDEDE9',
    fontFamily: 'Asap',
    favicon: '/img/svea-favicon.ico',
    selectIcon: '/img/chevron.svg',
    selectBoxShadow: '0 4px 4px rgba(0, 0, 0, 0.25)',
    headerBackground: '#FFFFFF',
    logoutLinkColor: '#00699A',
    buttonComponent: PrimaryButtonAlternate,
    marketingButtonColor: '#01699A',
    marketingButtonBackgroundColor: '#FFFFFF',
    marketingButtonBorderColor: '#01699A',
    UnallocatedPaymentButtonColor: '#FFFFFF',
    UnallocatedPaymentButtonBackgroundColor: '#00699A',
    UnallocatedPaymentButtonBorderColor: '#00699A',
    authMethod: InvoicerAuthMethod.IDS4_ONTIME,
    buttonIconPrimaryColor: '#01699A',
    buttonIconSecondaryColor: '#FFFFFF',
    buttonIconDisabledColor: '#00000042',
    languages: ['fi', 'sv', 'en'],
    footer: 'default',
    invoiceTitle: {
      background: 'url("/img/bg_gradient_circles.png") center center/cover',
      h1Color: '#00325C',
      h1FontStyle: 'italic',
      h1FontSize: '32px'
    },
    logo: {
      image: '/img/svea_logo_72px.png',
      alt: 'Svea',
      height: '72'
    },
    smallLogo: {
      image: '/img/svea_logo_small.png',
      alt: 'Svea',
      height: '32'
    },
    logout: {
      backgroundImage: 'url(/img/ontime_landing_bg.jpg)',
      h2FontStyle: 'italic',
      h2FontSize: '32px',
      h2FontWeight: 'inherit',
      h2TextTranform: 'inherit'
    },
    auth: {
      text: 'white',
      heading: '#434343',
      link: 'white',
      button: '#00699A',
      muted: 'white',
      divider: 'white',
      background: '#00699A',
      panel: '#00325C',
      inputBackground: '#FFFFFF',
      inputForeground: '#434343',
      pdfBackground: '#00699A',
      fontFamily: 'Asap',
      favicon: '/img/svea-favicon.ico',
      selectIcon: '/img/chevron.svg',
      selectBoxShadow: '0 4px 4px rgba(0, 0, 0, 0.25)',
      headerBackground: '#FFFFFF',
      logoutLinkColor: '#00699A',
      buttonComponent: PrimaryButtonAlternate,
      authMethod: InvoicerAuthMethod.IDS4_ONTIME,
      languages: ['fi', 'sv', 'en'],
      footer: 'default',
      invoiceTitle: {
        background: 'url("/img/bg_gradient_circles.png") center center/cover',
        h1Color: '#00325C',
        h1FontStyle: 'italic',
        h1FontSize: '32px'
      },
      logo: {
        image: '/img/svea_logo_72px.png',
        alt: 'Svea',
        height: '72'
      },
      smallLogo: {
        image: '/img/svea_logo_small.png',
        alt: 'Svea',
        height: '32'
      },
      logout: {
        backgroundImage: 'url(/img/ontime_landing_bg.jpg)',
        h2FontStyle: 'italic',
        h2FontSize: '32px',
        h2FontWeight: 'inherit',
        h2TextTranform: 'inherit'
      }
    }
  }
}

export default themes
