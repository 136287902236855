import backend from '../backend'
import { Branding } from '../const'
import store from '../store'
import themes from './themes'

export const getThemeName = async (invoiceId: string | null) => {
  let themeName = await getThemeNameFromBackend(invoiceId)
  if (themeName === Branding.NOT_FOUND) {
    themeName = getThemeNameFromState()
  }
  return themeName
}

const getThemeNameFromBackend = async (invoiceId: string | null) => {
  if (!invoiceId) {
    return Branding.NOT_FOUND
  }
  try {
    if (!getThemeNameFromState()) {
      return (await backend.getTheme(invoiceId))?.data?.theme
    }
    return getThemeNameFromState()
  } catch (error) {
    console.error(`invoiceId ${invoiceId} was most likely not an invoiceId.\n${error}`)
    return Branding.NOT_FOUND
  }
}

export const getThemePrimaryLanguage = () => {
  return getTheme(getThemeNameFromState()).languages[0]
}

export const getThemeFromState = () => {
  const theme = getThemeNameFromState()
  return getTheme(theme)
}

export const getThemeNameFromState = () => {
  return store.getState()?.common?.theme
}

const getTheme = (themeName: string) => {
  switch (themeName) {
    case Branding.ONTIME:
      return themes.ontime
    case Branding.INKASSO:
      return themes.inkasso
    case Branding.SERBIA:
      return themes.serbia
    case Branding.CREDITEXPRESS:
      return themes.creditExpress
    default:
      return themes.ontime
  }
}
