import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import LogoHeader from '../../ontime/components/Header/Header.component'
import SveaFooter from '../../ontime/components/Footer/Footer.component'
import { InvoiceLayoutWrapper } from '../../ontime/components/InvoiceLayout/InvoiceLayout.style'
import { RootState } from '../../common/store'
import DetailsBox from '../components/details-box/DetailsBox.component'
import { UP_OPEN_INVOICE } from '../utils/upLabels'
import { UnallocatedPaymentButton } from '../../components/buttons'
import { mapValuesToLabelsForSection } from '../utils/upLabelMapper'
import { ForwardArrowIcon } from '../../components/ButtonIcon.component/IconSvg'
import { IconColor, IconSize, InvoiceStatus } from '../../common/const'
import NoInvoicePage from '../../ontime/routes/NoInvoice.page'
import { createUPRequestObject } from '../utils/upRequestObjectBuilder'
import backend from '../../common/backend'
import { isInvoiceStatusAPaidStatus } from '../../common/helpers'
import { setInvoiceState } from '../../common/state/unallocatedPayment'

const UnallocatedPaymentOpenInvoice = () => {
  const { t } = useTranslation()
  const [isDisabled, setIsDisabled] = React.useState(false)
  const dispatch = useDispatch()
  const history = useHistory()
  const invoice = useSelector((state: RootState) => state.unallocatedPayment.invoiceData)
  const paymentType = useSelector((state: RootState) => state.unallocatedPayment.type)
  const jwtToken = useSelector((state: RootState) => state.auth.jwtToken)
  const sessionId = useSelector((state: RootState) => state.auth.sessionId)

  if (!invoice || !paymentType) {
    return <NoInvoicePage />
  }
  /*
  v1 warning: UP status is a default status (not error, handled, expired, etc.) in this view.
  There should be an open invoice to allocate and its status should not be a paid status here.
  */
  const isThereOpenInvoice = invoice.openInvoices.length > 0
  const openInvoiceStatus = invoice.openInvoices[0]?.status
  if (!isThereOpenInvoice || isInvoiceStatusAPaidStatus(openInvoiceStatus)) {
    return <NoInvoicePage />
  }

  const upDetailsLabelData = mapValuesToLabelsForSection(UP_OPEN_INVOICE[0].UP_DETAILS, invoice)
  const allocateToInvoice = mapValuesToLabelsForSection(
    UP_OPEN_INVOICE[0].ALLOCATE_TO_INVOICE,
    invoice
  )

  if (!upDetailsLabelData || !allocateToInvoice) {
    return <NoInvoicePage />
  }

  // Modifying the object to get the reference number from open invoice data
  allocateToInvoice.rows[4].value = invoice.openInvoices[0].referenceNumber

  const handleClick = () => {
    setIsDisabled(true)
    const { invoiceId, invoiceAmount } = invoice.openInvoices[0]
    const requestBodyObject = createUPRequestObject(
      null,
      [{ invoiceId, amount: invoiceAmount }],
      paymentType
    )

    if (!requestBodyObject) {
      return
    }

    if (!jwtToken || !sessionId) {
      return <Redirect to={`/${invoice.paymentId}/${paymentType}/auth`} />
    }

    dispatch(setInvoiceState(InvoiceStatus.LOADING))
    backend
      .postUnallocatedPayment(invoice.paymentId, jwtToken, sessionId, requestBodyObject)
      .then((response) => {
        if (response.status === 200) {
          window.location.reload()
        }
      })
      .catch((error) => {
        console.error('Error submitting data: ', error)
        if (error.response.status === 401) {
          history.push(`/${invoice.paymentId}/${paymentType}/auth`)
        } else {
          dispatch(setInvoiceState(InvoiceStatus.ERROR))
        }
      })
  }

  return (
    <InvoiceLayoutWrapper>
      <LogoHeader />
      <DetailsBox {...upDetailsLabelData} />
      <DetailsBox {...allocateToInvoice} />
      <UnallocatedPaymentButton onClick={handleClick} isDisabled={isDisabled}>
        {t('unallocatedPaymentAllocateToInvoice.button.label')}
        <ForwardArrowIcon
          color={isDisabled ? IconColor.DISABLED : IconColor.SECONDARY}
          size={IconSize.SMALL}
        />
      </UnallocatedPaymentButton>
      <SveaFooter />
    </InvoiceLayoutWrapper>
  )
}

export default UnallocatedPaymentOpenInvoice
