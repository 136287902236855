import * as React from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Provider } from 'react-redux'
import NotProdBanner from './components/NotProdBanner/NotProdBanner.component'
import OntimeApp from './OntimeApp'
import InkassoApp from './InkassoApp'
import UnallocatedPaymentsApp from './UnallocatedPaymentsApp'
import { Branding, UI } from './common/const'
import store, { RootState } from './common/store'
import backend from './common/backend'
import { setThemeName, setUIName, setAuthRequired, setInvoiceId } from './common/state/common'
import NoInvoicePage from './ontime/routes/NoInvoice.page'

const getInvoiceIdFromURL = (): string | null => {
  const urlParams = window.location.pathname.split('/')

  if (urlParams.length < 2) {
    return null
  }
  const urlInvoiceId = urlParams[1]

  // Currently the length of the invoiceId is 9
  // @todo Need to figure a way to validate invoiceId before passing it through
  return urlInvoiceId && urlInvoiceId !== 'callback' && urlInvoiceId.length === 9
    ? urlInvoiceId
    : null
}

const getInvoiceIdFromState = () => store.getState()?.invoice?.invoiceId || null

const AppUISelector = () => {
  const dispatch = useDispatch()
  const ui = useSelector((state: RootState) => state.common.ui)

  useEffect(() => {
    const fetchThemeAndUi = async () => {
      const invoiceId = getInvoiceIdFromURL() || getInvoiceIdFromState()

      if (!invoiceId) {
        dispatch(setThemeName(Branding.ONTIME))
        return
      }

      try {
        const response = await backend.getInitialData(invoiceId)
        dispatch(setInvoiceId(invoiceId))
        dispatch(setThemeName(response.data.theme))
        dispatch(setUIName(response.data.uiType))
        dispatch(setAuthRequired(response.data.authRequired))
      } catch (error) {
        console.error('Error calling getInitialData', error)
      }
    }

    fetchThemeAndUi()
  }, [dispatch])

  const renderApp = () => {
    switch (ui) {
      case UI.INKASSO:
        return <InkassoApp />
      case UI.UNALLOCATED_PAYMENTS:
        return <UnallocatedPaymentsApp />
      case UI.ONTIME:
        return <OntimeApp />
      default:
        return <NoInvoicePage />
    }
  }

  return (
    <>
      <NotProdBanner />
      {renderApp()}
    </>
  )
}

export const App = () => (
  <Provider store={store}>
    <AppUISelector />
  </Provider>
)
