import * as React from 'react'
import { Popup, PopupBackground, PopupMessage } from '../common/popupElements'
import { useRef, useState } from 'react'
import { BigSuccessIcon } from '../common/icons'
import { useTranslation } from 'react-i18next'
import {
  ButtonContainer,
  ErrorLabel,
  RatingOption,
  RatingSelectorWrapper,
  StyledFormControl,
  StyledMenuItem,
  StyledSelect,
  StyledTextField
} from './Feedback.style'
import { SelectChangeEvent, SvgIcon } from '@mui/material'
import { FeedbackTopics } from '../../../common/const'
import { PrimaryButton, SecondaryButton } from '../../../components/buttons'
import { FeedbackObject } from '../../../common/types/Feedback'
import { postFeedback } from '../../../common/backend'
import { useDispatch } from 'react-redux'
import { showFeedbackSuccess, showFeedbackForm } from '../../../common/state/common'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import CheckIcon from '@mui/icons-material/Check'

interface FeedbackPopupProps {
  invoiceId: string
}

const FeedbackPopup = ({ invoiceId }: FeedbackPopupProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const feedbackOptions = [
    { label: t('feedbackForm.feedback.label.veryPoor'), value: 1 },
    { label: t('feedbackForm.feedback.label.poor'), value: 2 },
    { label: t('feedbackForm.feedback.label.ok'), value: 3 },
    { label: t('feedbackForm.feedback.label.good'), value: 4 },
    { label: t('feedbackForm.feedback.label.veryGood'), value: 5 }
  ]

  const feedBackTopicDropdownOptions = [
    { value: '', label: t('feedbackForm.feedback.chooseTopic.label') },
    { value: FeedbackTopics.PAYMENT, label: t('feedbackForm.feedback.chooseTopic.Payment') },
    {
      value: FeedbackTopics.INVOICE_PREVIEW,
      label: t('feedbackForm.feedback.chooseTopic.InvoicePreview')
    },
    { value: FeedbackTopics.OTHER, label: t('feedbackForm.feedback.chooseTopic.Other') }
  ]

  const [isClosed, setIsClosed] = useState(false)
  const [selectedRatingFeedback, setSelectedRatingFeedback] = useState<number | null>(null)
  const [selectedFeedbackTopic, setSelectedFeedbackTopic] = useState<string | null>('')
  const [feedbackText, setFeedbackText] = useState<string | null>('')
  const [textareaEnabled, setTextareaEnabled] = useState<boolean>(false)
  const textareaRef = useRef<HTMLInputElement | null>(null)
  const [error, setError] = useState<string | null>('')
  const [open, setOpen] = useState(false)
  const [submitted, setSubmitted] = useState(false)

  const handleSetSelectedRatingFeedback = (value: number) => {
    setSelectedRatingFeedback(value)
    setError(null)
  }

  const handleTextareaChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFeedbackText(event.target.value)
    setError(null)
  }

  const handleDropdownChange = (event: SelectChangeEvent<unknown>) => {
    const value = event.target.value as string
    setSelectedFeedbackTopic(event.target.value as string)
    if (value === '') {
      setTextareaEnabled(false)
    } else {
      setTextareaEnabled(true)
      setError(null)
      setTimeout(() => {
        if (textareaRef.current) {
          textareaRef.current.focus()
        }
      }, 0)
    }
  }

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleCloseFeedbackForm = () => {
    setIsClosed(true)
    dispatch(showFeedbackForm(false))
  }

  const handleSendFeedback = async () => {
    if (selectedFeedbackTopic === '') {
      setError(t('feedbackForm.feedback.error.selectTopic'))
      return
    }

    if (selectedRatingFeedback === null && feedbackText?.length === 0) {
      setError(t('feedbackForm.feedback.error.selectRatingOrComment'))
      return
    }

    const rating = selectedRatingFeedback === null ? undefined : selectedRatingFeedback - 1
    const comment = feedbackText?.length === 0 ? undefined : feedbackText

    const feedbackObject = {
      rating,
      comment,
      subject: selectedFeedbackTopic
    } as FeedbackObject

    setSubmitted(true)
    const response = await postFeedback(invoiceId, feedbackObject)

    if (response.status === 204) {
      handleCloseFeedbackForm()
      setTimeout(() => dispatch(showFeedbackSuccess(true)), 400)
    } else {
      setError(t('feedbackForm.feedback.error.serverError'))
      setSubmitted(false)
      return
    }
  }

  return (
    <PopupBackground closed={isClosed}>
      <Popup closed={isClosed} align="center">
        <BigSuccessIcon alt={t('feedbackForm.title.label')!} />
        <PopupMessage>{t('feedbackForm.title.label')}</PopupMessage>
        <RatingSelectorWrapper>
          {feedbackOptions.map((option) => (
            <RatingOption
              key={option.value}
              isSelected={selectedRatingFeedback === option.value}
              onClick={() => handleSetSelectedRatingFeedback(option.value)}>
              <div className="circle">{option.value}</div>
              <div className="label">{option.label}</div>
            </RatingOption>
          ))}
        </RatingSelectorWrapper>

        <StyledFormControl variant="outlined">
          <StyledSelect
            value={selectedFeedbackTopic}
            onChange={handleDropdownChange}
            displayEmpty
            IconComponent={KeyboardArrowDownIcon}
            onOpen={handleOpen}
            onClose={handleClose}
            renderValue={(selected) => {
              const selectedOption = feedBackTopicDropdownOptions.find(
                (option) => option.value === selected
              )
              return selectedOption ? (
                <span>{selectedOption.label}</span>
              ) : (
                <span style={{ color: '#666' }}>
                  {t('feedbackForm.feedback.chooseTopic.label')}
                </span>
              )
            }}>
            {feedBackTopicDropdownOptions.map((option) => (
              <StyledMenuItem key={option.value} value={option.value}>
                {option.label}
                {open && selectedFeedbackTopic === option.value && (
                  <CheckIcon fontSize="small" style={{ marginLeft: 'auto', color: '#0e568b' }} />
                )}
              </StyledMenuItem>
            ))}
          </StyledSelect>
        </StyledFormControl>

        <StyledTextField
          inputRef={textareaRef}
          multiline
          rows={4}
          variant="outlined"
          value={feedbackText}
          disabled={!textareaEnabled}
          placeholder={t('feedbackForm.feedback.text.placeholder')}
          onChange={handleTextareaChange}
        />

        {error && <ErrorLabel>{error}</ErrorLabel>}

        <ButtonContainer>
          <PrimaryButton onClick={handleSendFeedback} disabled={submitted}>
            {t('feedbackForm.feedback.button.label.send')}
          </PrimaryButton>

          <SecondaryButton onClick={handleCloseFeedbackForm}>
            {t('feedbackForm.feedback.button.label.close')}
          </SecondaryButton>
        </ButtonContainer>
      </Popup>
    </PopupBackground>
  )
}
export default FeedbackPopup
