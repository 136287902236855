import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { RootState } from '../../common/store'
import {
  setAuthError,
  setAuthPending,
  startAuth,
  setAuthRedirectUrl
} from '../../common/state/auth'
import backend from '../../common/backend'
import { setMaintenance } from '../../common/state/common'
import MaintenancePage from '../../ontime/routes/Maintenance.page'
import AuthError from '../../ontime/components/AuthError/AuthError.component'
import AuthLogin from '../views/AuthLogin.view'

const UnallocatedPaymentAuth = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const invoiceId = useSelector((state: RootState) => state.common.invoiceId)
  const jwtToken = useSelector((state: RootState) => state.auth.jwtToken)
  const sessionId = useSelector((state: RootState) => state.auth.sessionId)
  const paymentType = useSelector((state: RootState) => state.unallocatedPayment.type)
  const authRedirectUrl = useSelector((state: RootState) => state.auth.redirectUrl)
  const authError = useSelector((state: RootState) => state.auth.errorMessage)

  React.useEffect(() => {
    const initAuthentication = () => {
      dispatch(setAuthPending(false))
      dispatch(setAuthError(''))
      backend
        .logInvoiceOpen(invoiceId)
        .catch((reason) => console.warn('Failed to log invoice open'))
    }
    initAuthentication()
  }, [dispatch, invoiceId])

  const onStartAuthExternal = (invoiceId: string) => {
    dispatch(setAuthPending(true))
    backend
      .postAuthForRedirect(invoiceId)
      .then((response) => {
        dispatch(
          startAuth({
            sessionId: response.data.sessionId,
            authUrl: response.data.authUrl,
            authPending: true
          })
        )
        dispatch(setAuthRedirectUrl(response.data.authUrl))
      })
      .catch((err) => {
        if (err.response.status === 503) {
          dispatch(setMaintenance())
        } else {
          console.log('Error response from backend: ', err)
          dispatch(setAuthPending(false))
          dispatch(setAuthError(t('authStartingError.label')))
        }
      })
  }

  return (
    <>
      {(() => {
        if (jwtToken && sessionId) {
          return <Redirect to={`/${invoiceId}/${paymentType}`} />
        }

        if (process.env.REACT_APP_MAINTENANCE === 'soft') {
          return <MaintenancePage />
        }

        if (authRedirectUrl) {
          window.location.href = authRedirectUrl
        }

        if (authError) {
          return <AuthError />
        }

        return <AuthLogin onStartAuth={() => onStartAuthExternal(invoiceId)} />
      })()}
    </>
  )
}

export default UnallocatedPaymentAuth
