import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { InvoiceDetailsPanel } from '../../../ontime/components/common/invoiceElements'
import { Heading } from '../../../ontime/components/LinkExpired/LinkedExpired.styles'
import { RefundFromRows } from '../../utils/upLabels'
import InputWithLabel from '../inputfieldwithlabel/InputFieldWithLabel.component'
import { getBankInfoFromIBAN } from '../../utils/ibanToBicMapper'
import { UnallocatedPaymentButton } from '../../../components/buttons'
import { ForwardArrowIcon } from '../../../components/ButtonIcon.component/IconSvg'
import { IconColor, IconSize } from '../../../common/const'
import { useEffect } from 'react'

export interface FormData {
  firstName?: string
  lastName?: string
  iban?: string
  bic?: string
}
interface DynamicFormProps {
  formRows: RefundFromRows
  submitButtonText: string
  submitHandler: (formData: FormData) => void
  isSubmitDisabled?: boolean
}

const DynamicForm = ({
  formRows,
  submitButtonText,
  submitHandler,
  isSubmitDisabled
}: DynamicFormProps) => {
  const { t, i18n } = useTranslation()
  const [formData, setFormData] = React.useState<FormData>({})
  const [formErrors, setFormErrors] = React.useState<{ [key: string]: string | null }>({})

  const updateBicTranslation = (value: string) => {
    const bic = getBankInfoFromIBAN(value).bic
    setFormData((prevState) => ({
      ...prevState,
      bic: bic || ''
    }))
  }

  const handleInputChange = (key: keyof FormData, value: string) => {
    setFormData((prevState) => ({
      ...prevState,
      [key]: value
    }))

    if (key === 'iban') {
      updateBicTranslation(value)
    }
  }

  const handleChange = (key: keyof FormData) => (event: React.ChangeEvent<HTMLInputElement>) => {
    handleInputChange(key, event.target.value)
  }

  const handleErrorChange = (key: keyof FormData, error: string | null) => {
    setFormErrors((prevState) => ({
      ...prevState,
      [key]: error
    }))
  }

  useEffect(() => {
    const handleLanguageChange = () => {
      if (formData.iban) {
        updateBicTranslation(formData.iban)
      }
    }

    i18n.on('languageChanged', handleLanguageChange)
    return () => {
      i18n.off('languageChanged', handleLanguageChange)
    }
  }, [i18n, formData.iban])

  const isDisabled =
    Object.values(formErrors).some((error) => error !== null) ||
    !formData.firstName ||
    !formData.lastName ||
    !formData.iban ||
    isSubmitDisabled
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (!isSubmitDisabled) {
      submitHandler(formData)
      console.log('Form submitted:')
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <InvoiceDetailsPanel>
        <Heading style={{ margin: '20px 0' }}>{t(formRows.title)}</Heading>
        {formRows.rows.map((row, index) => (
          <InputWithLabel
            key={index}
            label={row.label}
            type={row.type}
            fontWeight={row.fontWeight}
            isDisabled={row.isDisabled}
            isRequired={row.isRequired}
            value={formData[row.key as keyof FormData] || ''}
            onChange={handleChange(row.key as keyof FormData)}
            onErrorChange={(error) => handleErrorChange(row.key as keyof FormData, error)}
          />
        ))}
        <div style={{ textAlign: 'center' }}>
          {/* This button is not dynamic
            @todo: make a button component which can be dynamically used for the dynamic form
          */}
          <UnallocatedPaymentButton type="submit" isDisabled={isDisabled}>
            {t(submitButtonText)}
            <ForwardArrowIcon
              color={isDisabled ? IconColor.DISABLED : IconColor.SECONDARY}
              size={IconSize.SMALL}
            />
          </UnallocatedPaymentButton>
        </div>
      </InvoiceDetailsPanel>
    </form>
  )
}

export default DynamicForm
