import { configureStore, combineReducers } from '@reduxjs/toolkit'
import auth from './state/auth'
import common from './state/common'
import invoice from './state/invoice'
import pay from './state/pay'
import pdf from './state/pdf'
import unallocatedPayment from './state/unallocatedPayment'
import { loadState, saveState } from './localStorage'

export const persistedState = loadState()
const store = configureStore({
  reducer: { auth, common, invoice, pay, pdf, unallocatedPayment },
  preloadedState: persistedState,
  devTools: true
})

// on app state change, always save to local storage a small subset of the state which
// is enough to reload app after full refresh
store.subscribe(() => {
  saveState({
    auth: {
      jwtToken: store.getState().auth.jwtToken,
      sessionId: store.getState().auth.sessionId
    },
    common: {
      language: store.getState().common.language,
      theme: store.getState().common.theme,
      invoiceId: store.getState().common.invoiceId,
      ui: store.getState().common.ui,
      authRequired: store.getState().common.authRequired
    },
    invoice: {
      invoiceId: store.getState().invoice.invoiceId,
      storedInvoiceId: store.getState().invoice.storedInvoiceId,
      storedAmountToBePaid: store.getState().invoice.storedAmountToBePaid,
      previousInvoiceId: store.getState().invoice.previousInvoiceId,
      storedSubpage: store.getState().invoice.storedSubpage
    },
    unallocatedPayment: {
      type: store.getState().unallocatedPayment.type
    }
  })
})

export type RootState = ReturnType<typeof store.getState>

// https://stackoverflow.com/a/61132308
type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>
    }
  : T
export type PartialState = DeepPartial<RootState>

export const rootReducer = combineReducers({ auth, common, invoice, pay, pdf, unallocatedPayment })

export type AppStore = typeof store

export default store
