import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'
import { UnallocatedPaymentType, BackendInvoiceStatus } from '../const'
import { UnallocatedPaymentInvoice } from '../types/UnallocatedPayment'
import { InvoiceStatus } from '../const'

interface UnallocatedPaymentState {
  invoiceState: InvoiceStatus
  unallocatedPaymentStatus: BackendInvoiceStatus | null
  type: UnallocatedPaymentType | null
  invoiceData: UnallocatedPaymentInvoice | null
}

const INITIAL_STATE: UnallocatedPaymentState = {
  invoiceState: InvoiceStatus.NOT_LOADED,
  unallocatedPaymentStatus: null,
  type: null,
  invoiceData: null
}

const unallocatedPaymentSlice = createSlice({
  name: 'unallocatedPayment',
  initialState: INITIAL_STATE,
  reducers: {
    setInvoiceState: (
      state: Draft<UnallocatedPaymentState>,
      action: PayloadAction<InvoiceStatus>
    ) => {
      state.invoiceState = action.payload
    },

    setType: (
      state: Draft<UnallocatedPaymentState>,
      action: PayloadAction<UnallocatedPaymentType>
    ) => {
      state.type = action.payload
    },

    setInvoiceData: (
      state: Draft<UnallocatedPaymentState>,
      action: PayloadAction<{ status: InvoiceStatus; data: UnallocatedPaymentInvoice }>
    ) => {
      state.invoiceState = action.payload.status
      state.invoiceData = action.payload.data
    }
  }
})

export const { setInvoiceState, setType, setInvoiceData } = unallocatedPaymentSlice.actions
export default unallocatedPaymentSlice.reducer
