import { UnallocatedPaymentType } from '../../common/const'
import { RefundResponse } from '../../common/types/UnallocatedPayment'

interface OpenInvoice {
  invoiceId: string
  amount: number
}

export interface RequestObject {
  refund: RefundResponse | null
  openInvoices: OpenInvoice[]
}

export const createUPRequestObject = (
  refund: RefundResponse | null,
  openInvoices: OpenInvoice[] | null,
  type: UnallocatedPaymentType
): RequestObject | null => {
  switch (type) {
    case UnallocatedPaymentType.OPEN_INVOICE: {
      if (!openInvoices?.length) {
        return null
      }
      return {
        refund: null,
        openInvoices: openInvoices
      }
    }
    case UnallocatedPaymentType.REFUND: {
      if (!refund) {
        return null
      }
      return {
        refund: refund,
        openInvoices: []
      }
    }
    default:
      return null
  }
}
