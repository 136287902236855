import styled from 'styled-components/macro'

export const Wrapper = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  flex-grow: 0;
  flex-shrink: 0;
  color: white;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
`

export const WrapRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 3px;
  flex-shrink: 0;
  width: 100%;
  box-sizing: border-box;
  text-align: center;
`

export const WrapText = styled.div`
  text-align: center;
  width: 100%;
  flex-grow: 0;
  flex-shrink: 0;
  color: ${(props) => props.theme.muted};
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
`

export const WrapperWithSmallTopMargin = styled.div`
  margin-top: 10px;
`

export const WrapLink = styled.div`
  &:nth-child(1) {
    margin-left: 33px;
  }
  &:nth-child(2) {
    margin-right: 33px;
  }
  & a,
  & button {
    color: ${(props) => props.theme.link};
    font-size: 14px;
    font-weight: 600;
    white-space: nowrap;
    text-decoration: none;
    border-left: none;
    border-top: none;
    border-right: none;
    border-bottom: 1px solid ${(props) => props.theme.link};
    padding-bottom: 2px;
    line-height: 24px;
  }
  & button {
    background: none;
    margin: 0;
    padding: 0;
    cursor: pointer;
  }
`

export const FeedbackPopupHeader = styled.div`
  text-align: right;
  margin-top: 22px;
`

export const Divider = styled.hr`
  background-color: ${(props) => props.theme.link};
  height: 3px;
  width: 100%;
  margin: 30px 0;
  border: none;

  @media (max-width: 959px) {
    & {
      height: 3px;
      width: 80%;
      margin: 10px 0;
    }
  }
`
export const FeedBackWrapper = styled.div`
  &:nth-child(1) {
    margin-left: 33px;
  }
  &:nth-child(2) {
    margin-right: 33px;
  }
  & a,
  & button {
    color: ${(props) => props.theme.link};
    font-size: 14px;
    font-weight: 600;
    white-space: nowrap;
    text-decoration: none;
    border-left: none;
    border-top: none;
    border-right: none;
    border-bottom: 1px solid ${(props) => props.theme.link};
    padding-bottom: 2px;
    line-height: 24px;
  }
  & button {
    background: none;
    margin: 0;
    padding: 0;
    cursor: pointer;
  }
`
