import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { InvoiceDetailsPanel } from '../../../ontime/components/common/invoiceElements'
import {
  Divider,
  Header
} from '../../../ontime/components/InvoiceDetailsTypes/InvoiceDetails.style'
import { MappedSection } from '../../utils/upLabelMapper'
import InvoiceDetailRows from '../InvoiceDetailsRow/InvoiceDetailRows.component'

const DetailsBox = (section: MappedSection) => {
  const { t } = useTranslation()
  const rows = section.rows

  return (
    <InvoiceDetailsPanel>
      <Header>{t(section.title)}</Header>
      <Divider />
      <InvoiceDetailRows rows={rows} />
    </InvoiceDetailsPanel>
  )
}

export default DetailsBox
