import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { Popup, PopupBackground, PopupMessage } from '../common/popupElements'
import { BigSuccessIcon } from '../common/icons'
import { PrimaryButton } from '../../../components/buttons'
import { showFeedbackSuccess } from '../../../common/state/common'
import { useDispatch } from 'react-redux'

const FeedbackSuccessPopup = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [isClosed, setClosed] = useState(false)

  const close = () => {
    setClosed(true)
    dispatch(showFeedbackSuccess(false))
  }

  return (
    <PopupBackground closed={isClosed}>
      <Popup closed={isClosed} align="center">
        <BigSuccessIcon alt={t('feedbackForm.feedback.success.popup.title')!} />
        <PopupMessage>{t('feedbackForm.feedback.success.popup.title')}</PopupMessage>
        <PrimaryButton onClick={close}>
          {t('feedbackForm.feedback.button.label.close')}
        </PrimaryButton>
      </Popup>
    </PopupBackground>
  )
}

export default FeedbackSuccessPopup
