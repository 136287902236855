import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { PartialState } from './store'
import { getThemePrimaryLanguage } from './themes/theme'
import translationsEN from '../locales/translations/en.json'
import translationsFI from '../locales/translations/fi.json'
import translationsSV from '../locales/translations/sv.json'
import translationsSR from '../locales/translations/sr.json'

const getLangFromLocalStorage = () => {
  const state = localStorage.getItem('state')
  if (state) {
    const stateJson = JSON.parse(state) as PartialState
    if (stateJson && stateJson.common) {
      return stateJson.common.language
    }
  }
  return 'en'
}

const resources = {
  en: {
    translation: translationsEN
  },
  fi: {
    translation: translationsFI
  },
  sv: {
    translation: translationsSV
  },
  sr: {
    translation: translationsSR
  }
}

i18n.use(initReactI18next).init({
  resources,
  lng: getLangFromLocalStorage(), // For the tests, English will be use as the default language
  fallbackLng: getThemePrimaryLanguage(),
  debug: false,
  keySeparator: false,
  interpolation: {
    escapeValue: false // not needed for react!!
  }
})

export default i18n
