import axios, { AxiosResponse } from 'axios'
import { MarketingBannerData } from '../ontime/components/MarketingBanner/MarketingDataFetcher'
import {
  BackendInvoiceStatus,
  Branding,
  Interaction,
  InvoicerAuthMethod,
  InvoicerPaymentMethod,
  InvoiceStatus,
  UI
} from './const'
import { OpenInvoice, Refund } from './types/UnallocatedPayment'
import Invoice from './types/Invoice'
import TemplateConfiguration from './types/TemplateConfiguration'
import Pdf from './types/Pdf'
import { FeedbackObject } from './types/Feedback'
import { RequestObject } from '../unallocated_payment/utils/upRequestObjectBuilder'

const client = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    //TODO move to env variable
    'x-api-key': '1SDludQzD643lSoEXn0uDLCREwH8pqB1AvIZWOo8'
  }
})

export const logInvoiceOpen = (invoiceId: string): Promise<AxiosResponse<unknown>> => {
  return client.post(`client/invoices/${invoiceId}/logOpen`)
}

export const logInteraction = (
  invoiceId: string,
  interaction: Interaction,
  extraData: object | null = null
): Promise<AxiosResponse<unknown>> => {
  return client.post(`client/invoices/${invoiceId}/logInteraction`, { interaction, extraData })
}

export const getInvoice = (
  invoiceId: string,
  token: string | null,
  sessionId: string | undefined
): Promise<
  AxiosResponse<{
    status: BackendInvoiceStatus
    token: string
    invoice: Invoice
    templateConfiguration: TemplateConfiguration
    authentication: InvoicerAuthMethod
    paymentProvider: InvoicerPaymentMethod | null
    requireAuthentication: boolean
  }>
> => {
  const headers = {
    Authorization: token ? 'Bearer ' + token : null,
    SessionId: sessionId ?? null
  }
  return client.get(`/client/invoices/${invoiceId}`, {
    headers: headers
  })
}

export const payInkassoInvoice = (
  invoiceId: string,
  token: string,
  sessionId: string
): Promise<
  AxiosResponse<
    { url?: string; paymentProvider: InvoicerPaymentMethod; token: string } | { status: 'expired' }
  >
> => {
  const headers = {
    Authorization: token ? 'Bearer ' + token : null,
    SessionId: sessionId ?? null
  }
  return client.post(
    `/client/invoices/${invoiceId}/pay`,
    {},
    {
      headers: headers
    }
  )
}

export const createSveaPayment = (
  invoiceId: string,
  amount: number | null,
  sessionId: string,
  token: string
): Promise<AxiosResponse<{ pmt_paymenturl: string }>> => {
  return client.post(
    `/client/payment/sveaPaymentsCreate`,
    { invoiceId, sessionId, amount },
    {
      headers: {
        Authorization: token ? 'Bearer ' + token : null
      }
    }
  )
}

export const updateSveaPaymentStatus = (
  invoiceId: string,
  transactionId: string,
  sessionId: string,
  token: string
): Promise<AxiosResponse<unknown>> => {
  return client.post(
    `/client/payment/sveaPaymentsUpdateStatus`,
    { invoiceId, sessionId, transactionId },
    {
      headers: {
        Authorization: token ? 'Bearer ' + token : null
      }
    }
  )
}

export const getPdf = (
  invoiceId: string,
  sessionId: string,
  token: string
): Promise<AxiosResponse<Pdf>> => {
  return client.get(`/client/invoices/${invoiceId}/ontimePdf`, {
    headers: {
      Authorization: token ? 'Bearer ' + token : null,
      SessionId: sessionId
    }
  })
}

export const postAuthForRedirect = (
  invoiceId: string
): Promise<AxiosResponse<{ sessionId: string; authUrl: string }>> => {
  return client.post('/client/auth', { invoiceId })
}

export const postAuthSimple = (
  invoiceId: string,
  data: object
): Promise<AxiosResponse<{ sessionId: string; token: string }>> => {
  return client.post('/client/auth', { invoiceId, ...data })
}

export const getAuth = (
  sessionId: string,
  query: string
): Promise<
  AxiosResponse<
    | { status: 'completed'; token: string; redirectUrl: string }
    | { status: 'error'; errorText: string } // other statuses are also possible here
  >
> => {
  return client.get(`/client/auth/${sessionId}`, { params: { query } })
}

export const postFeedback = (
  invoiceId: string,
  feedback: FeedbackObject
): Promise<AxiosResponse<unknown>> => {
  return client.post(`/client/invoices/${invoiceId}/feedback`, { ...feedback })
}

export const getTheme = (
  invoiceId: string
): Promise<AxiosResponse<{ theme: Branding; invoiceType: UI }>> => {
  return client.get(`/client/invoices/${invoiceId}/theme`)
}

// TODO: unused for now
export const logout = (token: string): Promise<AxiosResponse<unknown>> => {
  const headers = {
    Authorization: token ? 'Bearer ' + token : null
  }
  return client.delete('/client/auth', {
    headers: headers
  })
}

export const getInvoiceMarketing = (
  invoiceId: string
): Promise<AxiosResponse<MarketingBannerData>> => {
  try {
    return client.get(`client/invoices/${invoiceId}/marketing`)
  } catch (error) {
    throw new Error(`Error fetching marketing data :  ${error}`)
  }
}

export const getInitialData = (
  invoiceId: string
): Promise<
  AxiosResponse<{
    theme: Branding
    uiType: UI
    authRequired: boolean
    templateConfigurationName: string
  }>
> => {
  return client.get(`/client/invoices/${invoiceId}/getInitialData`)
}

export const getUnallocatedPaymentInvoice = (
  invoiceId: string,
  token: string | null,
  sessionId: string | undefined
): Promise<
  AxiosResponse<{
    caseType: string
    paymentId: string
    merchantName: string
    registrationDate: string
    recipientName: string
    referenceNumber: string
    unallocatedAmount: number
    businessArea: string
    status: InvoiceStatus
    openInvoices: OpenInvoice[]
    refund: Refund | null
  }>
> => {
  const headers = {
    Authorization: token ? 'Bearer ' + token : null,
    SessionId: sessionId ?? null
  }
  return client.get(`/client/invoices/${invoiceId}`, {
    headers: headers
  })
}

export const postUnallocatedPayment = (
  invoiceId: string,
  token: string | null,
  sessionId: string | undefined,
  requestObject: RequestObject
) => {
  const headers = {
    Authorization: token ? 'Bearer ' + token : null,
    SessionId: sessionId ?? null
  }
  return client.post(`/client/invoices/${invoiceId}/unallocatedPayment`, requestObject, {
    headers: headers
  })
}

const backend = {
  logInvoiceOpen,
  logInteraction,
  getInvoice,
  createPayment: createSveaPayment,
  updatePaymentStatus: updateSveaPaymentStatus,
  getPdf,
  postAuthForRedirect,
  postAuthSimple,
  getAuth,
  postFeedback,
  getTheme,
  logout,
  getInitialData,
  getUnallocatedPaymentInvoice,
  postUnallocatedPayment
}

export default backend
