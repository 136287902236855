import * as React from 'react'
import { useTranslation } from 'react-i18next'
import LanguageSelector from '../LanguageSelector/LanguageSelector.component'
import { CallIcon } from '../common/icons'
import { useSelector, useDispatch } from 'react-redux'
import {
  WrapLink,
  WrapRow,
  WrapText,
  WrapperWithSmallTopMargin,
  Wrapper,
  Divider,
  FeedBackWrapper
} from './Footer.style'
import { getThemeFromState } from '../../../common/themes/theme'
import { showFeedbackForm } from '../../../common/state/common'
import { RootState } from '../../../common/store'

const FooterContentsDefault = ({ invoiceId }: { invoiceId?: string }) => {
  const { t } = useTranslation()
  const ui = useSelector((state: RootState) => state.common.ui)
  const dispatch = useDispatch()
  const currentYear = new Date().getFullYear()

  const handleShowFeedback = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault()
    if (invoiceId) {
      dispatch(showFeedbackForm(true))
    }
  }

  return (
    <>
      {ui === 'ontime' ? (
        <WrapRow>
          <LanguageSelector />
          <FeedBackWrapper>
            <a href="#" target="_blank" rel="noreferrer" onClick={handleShowFeedback}>
              {t('feedbackForm.feedback.link.label')}
            </a>
          </FeedBackWrapper>
        </WrapRow>
      ) : (
        <LanguageSelector />
      )}
      <WrapRow>
        <WrapLink>
          <a target="_blank" rel="noreferrer" href="https://svea.com">
            Svea.com
          </a>
        </WrapLink>
        <WrapLink>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.svea.com/fi/fi/kuluttajat/ota-yhteytta/?nav=40">
            {t('contact.label')}
          </a>
        </WrapLink>
      </WrapRow>
      <WrapRow>
        <WrapLink>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.svea.com/fi/fi/tietoa-meista/tietosuoja/">
            {t('privacypolicy.label')}
          </a>
        </WrapLink>
        <WrapLink>
          <a
            href={`/assets/doc/${t('accessibilityStatement.filename')}`}
            target="_blank"
            rel="noopener noreferrer">
            {t('accessibility.label')}
          </a>
        </WrapLink>
      </WrapRow>
      <WrapRow>
        <WrapText>© {currentYear} Svea Bank</WrapText>
      </WrapRow>
      <WrapRow>
        {/* prettier-ignore */}
        <WrapText>
          Mechelininkatu&nbsp;1a&nbsp;| 00180&nbsp;Helsinki&nbsp;| <CallIcon />&nbsp;09&nbsp;4242&nbsp;300
        </WrapText>
      </WrapRow>
    </>
  )
}

const FooterContentsSerbia = () => {
  return (
    <>
      <LanguageSelector />
      <WrapperWithSmallTopMargin>
        <WrapRow>
          <WrapText>+(381) 11 442 18 25</WrapText>
        </WrapRow>
        <WrapRow>
          <WrapText>©&nbsp;2023 Svea&nbsp;Finance d.o.o.</WrapText>
        </WrapRow>
      </WrapperWithSmallTopMargin>
    </>
  )
}

const FooterContentsCreditExpress = () => {
  return (
    <>
      <LanguageSelector />
      <WrapperWithSmallTopMargin>
        <WrapRow>
          <WrapText>011 442 18 05</WrapText>
        </WrapRow>
      </WrapperWithSmallTopMargin>
    </>
  )
}

const SveaFooter = ({ invoiceId }: { invoiceId?: string }) => {
  const footerTheme = getThemeFromState().footer

  return (
    <Wrapper>
      <Divider />
      {footerTheme === 'default' && <FooterContentsDefault invoiceId={invoiceId} />}
      {footerTheme === 'serbia' && <FooterContentsSerbia />}
      {footerTheme === 'creditexpress' && <FooterContentsCreditExpress />}
    </Wrapper>
  )
}

export default SveaFooter
