import i18next from 'i18next'

type BankInfo = {
  bic: string
  name: string
}

const ibanToBankMap: { [ibanPrefix: string]: BankInfo } = {
  '405': { bic: 'HELSFIHH', name: 'Aktia Pankki' },
  '497': { bic: 'DABAFIHH', name: 'Aktia Pankki' },
  '714': { bic: 'EVSEFIHH', name: 'Alisa Pankki' },
  '717': { bic: 'BIGKFIH1', name: 'Bigbank' },
  '713': { bic: 'CITIFIHX', name: 'Citibank' },
  '8': { bic: 'DABAFIHH', name: 'Danske Bank' },
  '34': { bic: 'DABAFIHH', name: 'Danske Bank' },
  '37': { bic: 'DNBAFIHX', name: 'DNB Bank ASA,' },
  '31': { bic: 'HANDFIHH', name: 'Handelsbanken' },
  '799': { bic: 'HOLVFIHH', name: 'Holvi' },
  '796': { bic: 'NARYFIH2', name: 'Narvi Payments' },
  '1': { bic: 'NDEAFIHH', name: 'Nordea' },
  '2': { bic: 'NDEAFIHH', name: 'Nordea' },
  '5': { bic: 'OKOYFIHH', name: 'OP Corporate Bank' },
  '33': { bic: 'ESSEFIHX', name: 'Skandinaviska Enskilda Banken' },
  '36': { bic: 'SBANFIHH', name: 'S-Pankki ' },
  '39': { bic: 'SBANFIHH', name: 'S-Pankki ' },
  '38': { bic: 'SBANFIHH', name: 'Swedbank' },
  '797': { bic: 'TRYEFIH2', name: 'TrueLayer (Ireland) Ltd' },
  '798': { bic: 'VPAYFIH2', name: 'Viva Payment Services S.A' },
  '6': { bic: 'SBANFIHH', name: 'Ålandsbanken (ÅAB)' },
  '470': { bic: 'POPFFI22', name: 'POP Pankit' },
  '471': { bic: 'POPFFI22', name: 'POP Pankit' },
  '472': { bic: 'POPFFI22', name: 'POP Pankit' },
  '473': { bic: 'POPFFI22', name: 'POP Pankit' },
  '474': { bic: 'POPFFI22', name: 'POP Pankit' },
  '475': { bic: 'POPFFI22', name: 'POP Pankit' },
  '476': { bic: 'POPFFI22', name: 'POP Pankit' },
  '477': { bic: 'POPFFI22', name: 'POP Pankit' },
  '478': { bic: 'POPFFI22', name: 'POP Pankit' },
  '479': { bic: 'POPFFI22', name: 'POP Pankit' },
  '715': { bic: 'ITELFIHH', name: 'Säästöpankki' },
  '400': { bic: 'ITELFIHH', name: 'Säästöpankki' },
  '402': { bic: 'ITELFIHH', name: 'Säästöpankki' },
  '403': { bic: 'ITELFIHH', name: 'Säästöpankki' },
  '406': { bic: 'ITELFIHH', name: 'Säästöpankki' },
  '407': { bic: 'ITELFIHH', name: 'Säästöpankki' },
  '408': { bic: 'ITELFIHH', name: 'Säästöpankki' },
  '410': { bic: 'ITELFIHH', name: 'Säästöpankki' },
  '411': { bic: 'ITELFIHH', name: 'Säästöpankki' },
  '412': { bic: 'ITELFIHH', name: 'Säästöpankki' },
  '414': { bic: 'ITELFIHH', name: 'Säästöpankki' },
  '415': { bic: 'ITELFIHH', name: 'Säästöpankki' },
  '416': { bic: 'ITELFIHH', name: 'Säästöpankki' },
  '417': { bic: 'ITELFIHH', name: 'Säästöpankki' },
  '419': { bic: 'ITELFIHH', name: 'Säästöpankki' },
  '420': { bic: 'ITELFIHH', name: 'Säästöpankki' },
  '421': { bic: 'ITELFIHH', name: 'Säästöpankki' },
  '423': { bic: 'ITELFIHH', name: 'Säästöpankki' },
  '424': { bic: 'ITELFIHH', name: 'Säästöpankki' },
  '425': { bic: 'ITELFIHH', name: 'Säästöpankki' },
  '426': { bic: 'ITELFIHH', name: 'Säästöpankki' },
  '427': { bic: 'ITELFIHH', name: 'Säästöpankki' },
  '428': { bic: 'ITELFIHH', name: 'Säästöpankki' },
  '429': { bic: 'ITELFIHH', name: 'Säästöpankki' },
  '430': { bic: 'ITELFIHH', name: 'Säästöpankki' },
  '431': { bic: 'ITELFIHH', name: 'Säästöpankki' },
  '432': { bic: 'ITELFIHH', name: 'Säästöpankki' },
  '435': { bic: 'ITELFIHH', name: 'Säästöpankki' },
  '436': { bic: 'ITELFIHH', name: 'Säästöpankki' },
  '437': { bic: 'ITELFIHH', name: 'Säästöpankki' },
  '438': { bic: 'ITELFIHH', name: 'Säästöpankki' },
  '439': { bic: 'ITELFIHH', name: 'Säästöpankki' },
  '440': { bic: 'ITELFIHH', name: 'Säästöpankki' },
  '441': { bic: 'ITELFIHH', name: 'Säästöpankki' },
  '442': { bic: 'ITELFIHH', name: 'Säästöpankki' },
  '443': { bic: 'ITELFIHH', name: 'Säästöpankki' },
  '444': { bic: 'ITELFIHH', name: 'Säästöpankki' },
  '445': { bic: 'ITELFIHH', name: 'Säästöpankki' },
  '446': { bic: 'ITELFIHH', name: 'Säästöpankki' },
  '447': { bic: 'ITELFIHH', name: 'Säästöpankki' },
  '448': { bic: 'ITELFIHH', name: 'Säästöpankki' },
  '449': { bic: 'ITELFIHH', name: 'Säästöpankki' },
  '450': { bic: 'ITELFIHH', name: 'Säästöpankki' },
  '451': { bic: 'ITELFIHH', name: 'Säästöpankki' },
  '452': { bic: 'ITELFIHH', name: 'Säästöpankki' },
  '454': { bic: 'ITELFIHH', name: 'Säästöpankki' },
  '455': { bic: 'ITELFIHH', name: 'Säästöpankki' },
  '456': { bic: 'ITELFIHH', name: 'Säästöpankki' },
  '457': { bic: 'ITELFIHH', name: 'Säästöpankki' },
  '458': { bic: 'ITELFIHH', name: 'Säästöpankki' },
  '459': { bic: 'ITELFIHH', name: 'Säästöpankki' },
  '460': { bic: 'ITELFIHH', name: 'Säästöpankki' },
  '461': { bic: 'ITELFIHH', name: 'Säästöpankki' },
  '462': { bic: 'ITELFIHH', name: 'Säästöpankki' },
  '463': { bic: 'ITELFIHH', name: 'Säästöpankki' },
  '464': { bic: 'ITELFIHH', name: 'Säästöpankki' },
  '483': { bic: 'ITELFIHH', name: 'Säästöpankki' },
  '484': { bic: 'ITELFIHH', name: 'Säästöpankki' },
  '485': { bic: 'ITELFIHH', name: 'Säästöpankki' },
  '486': { bic: 'ITELFIHH', name: 'Säästöpankki' },
  '487': { bic: 'ITELFIHH', name: 'Säästöpankki' },
  '488': { bic: 'ITELFIHH', name: 'Säästöpankki' },
  '489': { bic: 'ITELFIHH', name: 'Säästöpankki' },
  '490': { bic: 'ITELFIHH', name: 'Säästöpankki' },
  '491': { bic: 'ITELFIHH', name: 'Säästöpankki' },
  '492': { bic: 'ITELFIHH', name: 'Säästöpankki' },
  '495': { bic: 'ITELFIHH', name: 'Säästöpankki' },
  '496': { bic: 'ITELFIHH', name: 'Säästöpankki' }
}

export const getBankInfoFromIBAN = (iban: string): { bic: string; name: string } => {
  const cleanedIban = iban.replace(/\s/g, '')

  if (!cleanedIban.startsWith('FI') && !cleanedIban.startsWith('fi'))
    return {
      bic: i18next.t('unallocatedPaymentRefundFromDetails.bic.unknown'),
      name: i18next.t('unallocatedPaymentRefundFromDetails.bic.unknownBank')
    }
  const extractedPrefix = cleanedIban.slice(4, 7)

  const matchingKey = getMachedprefix(extractedPrefix)
  if (!matchingKey)
    return {
      bic: i18next.t('unallocatedPaymentRefundFromDetails.bic.unknown'),
      name: i18next.t('unallocatedPaymentRefundFromDetails.bic.unknownBank')
    }

  return ibanToBankMap[matchingKey]
}

const getMachedprefix = (prefix: string) => {
  const chars = prefix.split('')
  let concatenatedPrefix = ''
  let lastMatchedObj: string | undefined

  for (const character of chars) {
    concatenatedPrefix += character
    // eslint-disable-next-line no-loop-func
    const obj = Object.keys(ibanToBankMap).find((key) => key.startsWith(concatenatedPrefix))

    if (obj) {
      lastMatchedObj = obj
    } else {
      break
    }
  }

  return lastMatchedObj || null
}
