import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { authCompleted, setAuthError, setAuthPending, setSessionId } from '../../common/state/auth'
import { useLocation, Redirect } from 'react-router-dom'
import { RootState } from '../../common/store'
import backend from '../../common/backend'
import UnallocatedPaymentAuth from './Authentication.route'
import AuthError from '../../ontime/components/AuthError/AuthError.component'

const UnallocatedPaymentCallBack: React.FC = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const dispatch = useDispatch()

  const [redirectUrl, setRedirectUrl] = useState<string | null>(null)
  const paymentType = useSelector((state: RootState) => state.unallocatedPayment.type)
  const isAuthError = useSelector((state: RootState) => state.auth.errorMessage)

  useEffect(() => {
    const getAuth = async () => {
      const params = new URLSearchParams(location.search)
      const state = params.get('state')

      dispatch(setAuthPending(true))

      if (!state) {
        dispatch(setAuthError(t('authorizationError.label')))
        return
      }

      try {
        const response = await backend.getAuth(state, location.search.replace(/^\?/, ''))

        if (response.data.status === 'completed') {
          dispatch(authCompleted({ jwtToken: response.data.token }))
          dispatch(setSessionId(state))
          setRedirectUrl(response.data.redirectUrl)
        } else {
          dispatch(setAuthError(`${t('authorizationError.label')}: ${response.data.errorText}`))
        }
      } catch (error) {
        console.error('Error calling getAuth', error)
        dispatch(setAuthError(t('authorizationError.label')))
      }
    }

    getAuth()
  }, [])

  if (isAuthError) {
    return <AuthError />
  }

  if (redirectUrl) {
    return <Redirect to={`${redirectUrl}/${paymentType}`} />
  }

  return <UnallocatedPaymentAuth />
}

export default UnallocatedPaymentCallBack
