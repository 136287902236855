import * as React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { WithTranslation, withTranslation } from 'react-i18next'
import { removeState } from '../../common/localStorage'
import { clearAuth } from '../../common/state/auth'
import { PrimaryButton } from '../../components/buttons'
import { RootState } from '../../common/store'
import {
  LoggedOutLayout,
  LoggedOutMain,
  LoggedOutMessage,
  LogInButtonWrapper
} from '../components/LoggedOutElements/loggedOutElements.style'
import { LoggedOutLogo } from '../components/LoggedOutElements/loggedOutElements.component'
import { BigSuccessIcon } from '../components/common/icons'
import { getThemeFromState } from '../../common/themes/theme'
import { DefaultTheme } from 'styled-components/macro'

type LogoutPageProps = ConnectedProps<typeof connector> & WithTranslation
class LogoutPage extends React.Component<LogoutPageProps, { theme: DefaultTheme }> {
  componentDidMount() {
    this.props.clearAuth()
    removeState()
  }

  isNotUnallocatedPayment() {
    return this.props.invoiceId && !this.props.unallocatedPayment.invoiceData
  }

  render() {
    const { t, invoiceId } = this.props
    const theme = getThemeFromState()
    return (
      <LoggedOutLayout>
        <LoggedOutMain>
          <LoggedOutLogo {...theme.logo} />
        </LoggedOutMain>
        <LoggedOutMessage>
          <BigSuccessIcon alt={t('loggedOut.label')!} />
          <h2>{t('loggedOut.label')}</h2>
          {this.isNotUnallocatedPayment() && (
            <LogInButtonWrapper>
              <PrimaryButton onClick={() => (window.location.href = '/' + invoiceId + '/auth')}>
                {t('logIn.label')}
              </PrimaryButton>
            </LogInButtonWrapper>
          )}
        </LoggedOutMessage>
      </LoggedOutLayout>
    )
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    invoiceId: state.invoice.invoiceId,
    unallocatedPayment: state.unallocatedPayment
  }
}

const connector = connect(mapStateToProps, { clearAuth })
export default connector(withTranslation()(LogoutPage))
