import * as React from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'
import { RootState } from '../../../common/store'
import { LogoutLink, Wrapper } from './Header.style'
import { getThemeFromState } from '../../../common/themes/theme'
import { useDispatch } from 'react-redux'
import { clearAuth } from '../../../common/state/auth'
import { Redirect } from 'react-router-dom'

type LogoHeaderConnectedProps = ConnectedProps<typeof connector>

const LogoHeader = ({ jwtToken, sessionId, authenticationRequired }: LogoHeaderConnectedProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const theme = getThemeFromState()
  const [logOut, setLogOut] = useState<boolean>(false)

  const handleLogoutClick = () => {
    setLogOut(true)
    if (!authenticationRequired) {
      dispatch(clearAuth())
    }
  }

  return logOut && authenticationRequired ? (
    <Redirect to={`/logout`} />
  ) : (
    <Wrapper>
      <img src={theme.smallLogo.image} alt={theme.smallLogo.alt} height={theme.smallLogo.height} />
      {jwtToken && sessionId && (
        <LogoutLink
          style={{
            cursor: 'pointer'
          }}
          onClick={handleLogoutClick}>
          {t('ontimeLogoutButton.label')}
        </LogoutLink>
      )}
    </Wrapper>
  )
}

const connector = connect((state: RootState) => ({
  jwtToken: state.auth.jwtToken,
  sessionId: state.auth.sessionId,
  authenticationRequired: state.common.authRequired
}))
export default connector(LogoHeader)
