import * as React from 'react'
import { useSelector } from 'react-redux'
import LogoHeader from '../../ontime/components/Header/Header.component'
import SveaFooter from '../../ontime/components/Footer/Footer.component'
import { InvoiceLayoutWrapper } from '../../ontime/components/InvoiceLayout/InvoiceLayout.style'
import { RootState } from '../../common/store'
import { mapValuesToLabelsForSection } from '../utils/upLabelMapper'
import { UP_REFUND } from '../utils/upLabels'
import { formatIban } from '../../common/helpers'
import NoInvoicePage from '../../ontime/routes/NoInvoice.page'
import SuccessBox from '../components/success-box/SuccessBox.component'
import { getBankInfoFromIBAN } from '../utils/ibanToBicMapper'
import MessageBox from '../components/message-box/MessageBox.component'

const UnallocatedPaymentRefundSuccess = () => {
  const invoice = useSelector((state: RootState) => state.unallocatedPayment.invoiceData)

  /*
  v1 warning: UP status is 'handled' in this view.
  In this view there should be a refund
  */
  if (!invoice || !invoice.refund) {
    return <NoInvoicePage />
  }

  const refundSuccessMessage = UP_REFUND[1].REFUND_SUCCESS_MESSAGE
  const refundSuccess = mapValuesToLabelsForSection(UP_REFUND[1].REFUND_SUCCESS, invoice)

  if (!refundSuccess || !refundSuccessMessage) {
    return <NoInvoicePage />
  }

  // modifying object for mapping generated BIC
  const bankData = getBankInfoFromIBAN(invoice.refund.iban)
  refundSuccess.rows[2].value = formatIban(invoice.refund.iban)
  refundSuccess.rows[1].value = bankData.bic

  // @todo inside the mapped object add separate sections for possible business areas and make the sub object nested based on the business areas.
  // And after that remove all unnecessary object manipulations
  // Modifying the object for AFS

  if (invoice.businessArea === 'AFS') {
    delete refundSuccess.rows[4]
    delete refundSuccess.rows[5]

    // This not a good practice to inject values on the fly to an object. Remove this after done necessary changes which is mentioned above
    refundSuccess.rows[3]['amount'] = 'unallocatedPaymentRefundSuccess.refundableAmountAFS.label'
  }

  const successMessage =
    invoice.businessArea === 'AFS'
      ? refundSuccessMessage.messageAFS
      : refundSuccessMessage.messageWP

  return (
    <InvoiceLayoutWrapper>
      <LogoHeader />
      <SuccessBox section={refundSuccess} isDivider={false} highlightedRowsNumber={3} />
      <MessageBox message={successMessage} />
      <SveaFooter />
    </InvoiceLayoutWrapper>
  )
}

export default UnallocatedPaymentRefundSuccess
