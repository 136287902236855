import * as React from 'react'
import { MappedRow } from '../../utils/upLabelMapper'
import { InvoiceRow } from '../../../ontime/components/common/invoiceElements'
import { FormatAmount, FormatDate } from '../../../common/formatters'
import { useTranslation } from 'react-i18next'

interface InvoiceDetailsRowProps {
  rows: MappedRow[]
}

const InvoiceDetailsRows = ({ rows }: InvoiceDetailsRowProps) => {
  const { t } = useTranslation()
  return (
    <>
      {rows.map((row, index) => {
        const objectKey = Object.keys(row)[0]
        const { type, fontWeight, value } = row
        return (
          <InvoiceRow key={index} size="small">
            <label>{t(row[objectKey])}</label>
            <span style={{ fontWeight }}>
              {type === 'date' ? (
                <FormatDate>{String(value)}</FormatDate>
              ) : type === 'amount' ? (
                <>
                  <FormatAmount>{Number(value)}</FormatAmount> €
                </>
              ) : (
                value
              )}
            </span>
          </InvoiceRow>
        )
      })}
    </>
  )
}

export default InvoiceDetailsRows
